import { t } from "@lingui/macro";
import { message } from "antd";
import { atom, getDefaultStore, useSetAtom } from "jotai";
import { selectAtom } from "jotai/utils";
import { first, isEqual } from "lodash";
import omit from "lodash/omit";

import { appConfigAtom } from "atoms/configurations";
import { versionedAtomWithStorage } from "atoms/utils";
import { logChangedMapViewSettings } from "components/map/atoms/logs";

import { MapLayers } from "components/deck-gl/instance";
import { mapLayers } from "components/deck-gl/layers/layers";
import mapSettings from "components/map/controls/map-settings";
import { DEFAULT_VERTICAL_SPLIT_VIEW } from "components/map/hooks/layout";
import { isUndefinedOrNull } from "components/map/utils";

export const mapSettingsKey = "navirec-map-settings-v1";

export type ActiveRightSidePanel =
  | "map-layer"
  | "map-settings"
  | "data-layers"
  | "route-planner"
  | "send-message"
  | "search-vehicles"
  | undefined;

export interface MapSettings {
  mapLayerProvider?: MapLayers;
  showTrafficLayer: boolean;
  showAreas: boolean;
  showPoints: boolean;
  showVehicleNames: boolean;
  smallerVehicleIcons: boolean;
  smallerPointsIcons: boolean;
  showStats?: boolean;
  groupVehicles: boolean;
  showFullDay: boolean;
  chartAndMapViewHeight?: number[];
  listAndMapViewWidth: number[];
  showDetailsInVehiclesListView: boolean;
  showDetailsInDriversListView: boolean;
  showTimeInPlaybackRangePicker: boolean;
  activeRightSidePanel: ActiveRightSidePanel;
  showRecentTrailingLine?: boolean;
  //playback
  showParkingTimes: boolean;
  colorTrips: boolean;
  showFuture?: boolean;
  zoomToCurrentTrip?: boolean;
  showEventsIcon?: boolean;
  showStopsIcon?: boolean;
  showTrailingLine?: boolean;
  iconAnimation?: boolean;
  showDirections?: boolean;
  showActivities?: boolean;
  playbackDuration: number;
  showStreamingStats?: boolean;
  showAddressSearchBackendsChoices?: boolean;
  enableSoundAlerts?: boolean;
  focusOnAlertedVehicles?: boolean;
}

const mapSettingsDefault = {
  mapLayerProvider: undefined,
  showTrafficLayer: false,
  showAreas: true,
  showPoints: true,
  showVehicleNames: true,
  smallerVehicleIcons: true,
  showStats: false,
  smallerPointsIcons: true,
  groupVehicles: true,
  showFullDay: false,
  showParkingTimes: true,
  colorTrips: true,
  chartAndMapViewHeight: [70, 30],
  listAndMapViewWidth: DEFAULT_VERTICAL_SPLIT_VIEW,
  showDetailsInVehiclesListView: false,
  showDetailsInDriversListView: false,
  showTimeInPlaybackRangePicker: false,
  activeRightSidePanel: undefined,
  showFuture: false,
  zoomToCurrentTrip: true,
  showEventsIcon: true,
  showStopsIcon: true,
  showRecentTrailingLine: true,
  showTrailingLine: false,
  iconAnimation: true,
  showDirections: true,
  showActivities: true,
  playbackDuration: 5,
  showStreamingStats: false,
  showAddressSearchBackendsChoices: false,
  enableSoundAlerts: false,
  focusOnAlertedVehicles: false,
};

const mapSettingsAtom = versionedAtomWithStorage<MapSettings>(mapSettingsKey, mapSettingsDefault, [
  {
    key: "navirec-map-layer-settings-v1",
    fieldMappings: {
      provider: "mapLayerProvider",
      vehicleNames: "showVehicleNames",
      showVehicleDetails: "showDetailsInVehiclesListView",
      showDriverDetails: "showDetailsInDriversListView",
      mapControlMenu: "activeRightSidePanel",
      showAddressSearchBackends: "showAddressSearchBackendsChoices",
    },
  },
]);
mapSettingsAtom.debugLabel = "mapSettings";

const store = getDefaultStore();

const getMapLayerProvider = (provider?: MapLayers) => {
  const config = store.get(appConfigAtom);
  const layers = config?.map_layers;

  if ((provider && !layers?.includes(provider)) || !provider) {
    // Find the first available layer that exists in availableMapLayers
    const validLayer = layers?.find((layer) => Object.keys(mapLayers()).includes(layer));
    return validLayer || (first(layers) as MapLayers);
  }

  return provider;
};

export const mapLayerProviderSelector = selectAtom(mapSettingsAtom, (settings) => {
  return getMapLayerProvider(settings.mapLayerProvider);
});
export const showTrafficLayerSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showTrafficLayer) ? mapSettingsDefault.showTrafficLayer : settings.showTrafficLayer
);
export const showVehicleNamesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showVehicleNames) ? mapSettingsDefault.showVehicleNames : settings.showVehicleNames
);
export const smallerVehicleIconsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.smallerVehicleIcons)
    ? mapSettingsDefault.smallerVehicleIcons
    : settings.smallerVehicleIcons
);
export const smallerPointsIconsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.smallerPointsIcons) ? mapSettingsDefault.smallerPointsIcons : settings.smallerPointsIcons
);
export const showStatsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showStats) ? mapSettingsDefault.showStats : settings.showStats
);
export const groupVehiclesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.groupVehicles) ? mapSettingsDefault.groupVehicles : settings.groupVehicles
);
export const showFullDaySelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showFullDay) ? mapSettingsDefault.showFullDay : settings.showFullDay
);
export const chartAndMapViewHeightSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.chartAndMapViewHeight)
    ? mapSettingsDefault.chartAndMapViewHeight
    : settings.chartAndMapViewHeight
);
export const listAndMapViewWidthSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.listAndMapViewWidth)
    ? mapSettingsDefault.listAndMapViewWidth
    : settings.listAndMapViewWidth
);
export const showDetailsInVehiclesListViewSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showDetailsInVehiclesListView)
    ? mapSettingsDefault.showDetailsInVehiclesListView
    : settings.showDetailsInVehiclesListView
);
export const showDetailsInDriversListViewSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showDetailsInDriversListView)
    ? mapSettingsDefault.showDetailsInDriversListView
    : settings.showDetailsInDriversListView
);
export const activeRightSidePanelSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.activeRightSidePanel)
    ? mapSettingsDefault.activeRightSidePanel
    : settings.activeRightSidePanel
);
export const showRecentTrailingLineSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showRecentTrailingLine)
    ? mapSettingsDefault.showRecentTrailingLine
    : settings.showRecentTrailingLine
);
export const showParkingTimesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showParkingTimes) ? mapSettingsDefault.showParkingTimes : settings.showParkingTimes
);
export const colorTripsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.colorTrips) ? mapSettingsDefault.colorTrips : settings.colorTrips
);
export const playbackDurationSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.playbackDuration) ? mapSettingsDefault.playbackDuration : settings.playbackDuration
);
export const showStreamingStatsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showStreamingStats) ? mapSettingsDefault.showStreamingStats : settings.showStreamingStats
);
export const showFutureSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showFuture) ? mapSettingsDefault.showFuture : settings.showFuture
);
export const zoomToCurrentTripSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.zoomToCurrentTrip) ? mapSettingsDefault.zoomToCurrentTrip : settings.zoomToCurrentTrip
);
export const showEventsIconSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showEventsIcon) ? mapSettingsDefault.showEventsIcon : settings.showEventsIcon
);
export const showStopsIconSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showStopsIcon) ? mapSettingsDefault.showStopsIcon : settings.showStopsIcon
);
export const showTrailingLineSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showTrailingLine) ? mapSettingsDefault.showTrailingLine : settings.showTrailingLine
);
export const iconAnimationSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.iconAnimation) ? mapSettingsDefault.iconAnimation : settings.iconAnimation
);
export const showDirectionsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showDirections) ? mapSettingsDefault.showDirections : settings.showDirections
);
export const showActivitiesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showActivities) ? mapSettingsDefault.showActivities : settings.showActivities
);
export const showAddressSearchBackendsChoicesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.showAddressSearchBackendsChoices)
    ? mapSettingsDefault.showAddressSearchBackendsChoices
    : settings.showAddressSearchBackendsChoices
);
export const enableSoundAlertsSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.enableSoundAlerts) ? mapSettingsDefault.enableSoundAlerts : settings.enableSoundAlerts
);

export const focusOnAlertedVehiclesSelector = selectAtom(mapSettingsAtom, (settings) =>
  isUndefinedOrNull(settings.focusOnAlertedVehicles)
    ? mapSettingsDefault.focusOnAlertedVehicles
    : settings.focusOnAlertedVehicles
);

export const isSettingsUnchangedAtom = atom((get) => {
  const settings = get<MapSettings>(mapSettingsAtom);
  const mapSettings = { ...mapSettingsDefault, ...settings };

  return isEqual(omit(mapSettings, ["activeRightSidePanel"]), omit(mapSettingsDefault, ["activeRightSidePanel"]));
});

const ignoreMapClickActionAtom = atom<boolean>(false);
ignoreMapClickActionAtom.debugPrivate = true;

const ignoreMapViewStateChangeAtom = atom<boolean>(false); // preserves map view port even when values changes.
ignoreMapViewStateChangeAtom.debugPrivate = true;

const useMapSettings = () => {
  const setSettings = useSetAtom(mapSettingsAtom);

  const onChangeSettings = (key: string, value: string | boolean | number[] | number | ActiveRightSidePanel) => {
    logChangedMapViewSettings(key, value);

    setSettings((res: MapSettings) => ({
      ...res,
      [key]: value,
    }));
  };

  const onResetSettings = () => {
    setSettings({ ...mapSettingsDefault, activeRightSidePanel: "map-settings" });
    message.success(t`Default map settings has been applied.`);
  };

  return { onChangeSettings, onResetSettings };
};

export { mapSettingsAtom, useMapSettings, ignoreMapClickActionAtom, ignoreMapViewStateChangeAtom };
