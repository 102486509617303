import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { isEmpty } from "lodash";

import { httpClientAtom } from "atoms/httpclient";
import { isMultiViewAtom, isTimelineView, mapTabAtom, objectIdAtom, timelineDatesAtom } from "components/map/atoms/map";
import { dayTotalSettingsAtom, DayTotalsStore, timelineTotalsAtom } from "components/map/atoms/timeline/daytotals";
import { getDayTotalsAtom, getTimelinePath } from "components/map/atoms/timeline/url";
import { getExceedMAXTimelineDays } from "components/map/atoms/timeline/utils";

import { mergeDayTotalsSettingsWithValues } from "components/map/utils";

import { formatDate } from "utils/datetime-utils";

const dayTotalsLoadingAtom = atom<boolean>(false);
dayTotalsLoadingAtom.debugLabel = "timelineLoading";

const dayTotalsUrlAtom = atom<string>((get) => {
  const httpClient = get(httpClientAtom);
  const timelineDates = get(timelineDatesAtom);
  const tab = getTimelinePath(get(mapTabAtom));
  const objectId = get(objectIdAtom);
  const dayTotals = get(getDayTotalsAtom);
  const start = timelineDates?.[0];
  const end = timelineDates?.[1];

  const isTimelineOnSameDay = start?.isSame(end?.subtract(1, "second"), "day");
  const shouldFetchDayTotals =
    (!isTimelineOnSameDay && get(isTimelineView)) || getExceedMAXTimelineDays(timelineDates, 1);

  const url = `${
    httpClient.basePath
  }${tab}_timeline/totals/?vehicle=${objectId}&start_time=${start?.toISOString()}&end_time=${end?.toISOString()}${
    dayTotals ? `&totals=${dayTotals}` : ""
  }`;

  if (get(isMultiViewAtom)) {
    return "";
  }

  if (shouldFetchDayTotals) {
    return url;
  }

  return "";
});
dayTotalsUrlAtom.debugPrivate = true;

const fetchDayTotalsEffect = atomEffect((get, set) => {
  const dayTotalsUrl = get(dayTotalsUrlAtom);
  const httpClient = get(httpClientAtom);

  if (isEmpty(dayTotalsUrl)) {
    return;
  }

  set(dayTotalsLoadingAtom, true);

  httpClient.get(dayTotalsUrl, {
    successHandler: (res) => {
      const dailyTotals: { [key: string]: DayTotalsStore[] } = {};

      res?.totals_daily.forEach((total: { [x: string]: any }) => {
        dailyTotals[formatDate(total.day)] = mergeDayTotalsSettingsWithValues(total, get(dayTotalSettingsAtom));
      });

      set(timelineTotalsAtom, {
        totals: mergeDayTotalsSettingsWithValues(res.totals, get(dayTotalSettingsAtom)),
        dailyTotals: dailyTotals,
      });
      set(dayTotalsLoadingAtom, false);
    },
    errorHandler: () => {
      set(dayTotalsLoadingAtom, false);
    },
  });

  return () => {
    set(timelineTotalsAtom, undefined);
  };
});
fetchDayTotalsEffect.debugPrivate = true;

export { fetchDayTotalsEffect, dayTotalsLoadingAtom, dayTotalsUrlAtom };
