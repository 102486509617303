import dayjs, { Dayjs } from "dayjs";
import { atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { flatten, isEmpty } from "lodash";

import { timelineTimeAtom } from "components/map/atoms/map";
import { mapSettingsAtom } from "components/map/atoms/settings";
import { ListViewEvent, timelineAtom } from "components/map/atoms/timeline";
import { timelineResponseDateAtom } from "components/map/atoms/timeline/fetch";
import { showFullDaySelector } from "components/map/atoms/timeline/playback";

const getFirstAndLastDrivingTime = (
  timelineEvents: ListViewEvent[] = [],
  maxDate?: Dayjs,
  padTimeInMinutes: number = 1
) => {
  const tripTimes = timelineEvents
    ?.filter((hist) => ["driving", "towing", "idling"].includes(hist.vehicleStatus as string))
    ?.map((hist) => ({
      start: dayjs.tz(dayjs(hist.event.trip?.start_time)),
      end: dayjs.tz(dayjs(hist.event.trip?.end_time || undefined)),
    }));

  const minTripTime =
    tripTimes.length > 0 ? tripTimes?.reduce((min, curr) => (curr.start.isBefore(min.start) ? curr : min)) : undefined;
  const maxTripTime =
    tripTimes.length > 0 ? tripTimes?.reduce((max, curr) => (curr.end.isAfter(max.end) ? curr : max)) : undefined;

  let start = minTripTime?.start?.subtract(padTimeInMinutes, "minute");
  let end = maxTripTime?.end?.add(padTimeInMinutes, "minute");

  if (start?.isBefore(minTripTime?.start?.startOf("day"))) {
    start = minTripTime?.start;
  }

  if (end?.isAfter(maxTripTime?.end?.endOf("day"))) {
    end = maxTripTime?.end;
  }

  if (maxDate?.isBefore(end)) {
    end = maxDate;
  }

  return [start, end];
};

const chartTimeRangeAtom = atom((get) => {
  const showFullDay = get(selectAtom(mapSettingsAtom, showFullDaySelector));
  const playbackDate = get(timelineTimeAtom);
  const keyedTimeline = get(timelineAtom);
  const maxDate = dayjs.tz(dayjs(get(timelineResponseDateAtom)));

  if (isEmpty(playbackDate)) {
    return [];
  }

  /* if vehicle histories is empty or contains only parking or offline activities show chart range from start to end of day or activity. */
  const timelineEvents = flatten(Object.values(keyedTimeline).map((event) => event.listViewEvents));
  const isEmptyOrOnlyParkingOrOffline = timelineEvents.every((event) =>
    ["parking", "offline"]?.includes(event.vehicleStatus)
  );

  const startOfDay = playbackDate?.startOf("day");
  const endOfDay = playbackDate?.add(1, "day").startOf("day");
  const endOfHistory = maxDate?.isBefore(endOfDay) ? maxDate : endOfDay;

  const fullDateRange = [startOfDay, endOfDay];

  if (!showFullDay && !isEmptyOrOnlyParkingOrOffline) {
    const bounds = getFirstAndLastDrivingTime(timelineEvents, endOfHistory, 15);
    if (bounds) {
      return bounds;
    }
  }

  return fullDateRange;
});
chartTimeRangeAtom.debugLabel = "chartTimeRange";

export { chartTimeRangeAtom };
