import { t } from "@lingui/macro";
import { message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { getDefaultStore } from "jotai";
import { isEmpty, split } from "lodash";
import nProgress from "nprogress";

import { mapStateAtom, mapTabs, MapViewType } from "components/map/atoms/map";
import { previewTimelineEventIdAtom } from "components/map/atoms/timeline";
import { timelineData_Atom, timelineResponseErrorAtom } from "components/map/atoms/timeline/fetch";
import {
  fixedPlaybackTimeAtom,
  playbackElapsedTimeAtom,
  playbackStatusAtom,
  playbackTimeAtom,
} from "components/map/atoms/timeline/playback";

import { editingDataLayerAtom } from "components/map/data-layers/editor";
import { pageLoadStartAtAtom } from "components/measure-page-load";

import { isURLString } from "utils/url-utils";
import { isUUID } from "utils/uuid-utils";

const parseUrl = (
  path: string
): {
  segments: string[];
  query: URLSearchParams;
} => {
  const basePath = isURLString(path) ? undefined : window.location.origin;
  const fullUrl = new URL(path, basePath);

  const segments = fullUrl.pathname.split("/").filter((segment) => segment !== "");
  const query = new URLSearchParams(fullUrl.search);

  return { segments, query };
};

const parseTime = (time: string | null) => {
  if (!time) {
    return undefined;
  }

  const cleanedTime = time.replace(" ", "+"); // clean up url encoding + to spaces
  const parsedTime = dayjs(cleanedTime);

  if (parsedTime.isValid()) {
    return parsedTime;
  }

  message.error(t`unsupported date-time format in URL.`);
  return undefined;
};

const formatTimelineDates = (startTime: string | null, endTime: string | null): [Dayjs, Dayjs] | undefined => {
  if (startTime && endTime) {
    return [dayjs.tz(dayjs(startTime)), dayjs.tz(dayjs(endTime))];
  }

  return undefined;
};

const store = getDefaultStore();

const measurePageLoadTime = (tab: string | null, view: string | null) => {
  if (view !== store.get(mapStateAtom)?.view || tab !== store.get(mapStateAtom)?.tab) {
    store.set(pageLoadStartAtAtom, dayjs());
  }
};

const show404Error = (msg: string = t`We could not find the page you're looking for.`) => {
  message.error(msg, 10);
  nProgress.done();

  return;
};

const getObjectIds = (objectIdsInParams: string | null, objectId: string | null) => {
  if (objectIdsInParams) {
    return split(objectIdsInParams, ",");
  }

  if (objectId && !["new", "edit"].includes(objectId)) {
    return [objectId];
  }

  return undefined;
};

const getViewName = (view: string, objectLength: number, isMultiView?: boolean, segment2?: string, tab?: string) => {
  if (tab === mapTabs.AREAS) {
    return view || segment2 || "list";
  }

  if (isMultiView && objectLength <= 10) {
    return `multi-${segment2 || "live"}` as MapViewType;
  }

  return (view as MapViewType) || (segment2 ? "live" : "list");
};

const validateViewName = (view: string) => {
  return (
    view &&
    !["live", "list", "playback", "timeline", "multi-playback", "multi-timeline", "multi-live", "new", "edit"].includes(
      view
    )
  );
};

const validateObjectIds = (objectIds?: string[]) => {
  return !isEmpty(objectIds) && objectIds?.some((objectId: string) => !isUUID(objectId));
};

/**
 * Resets playback state to default values.
 */
const restoreInitialMapState = (objectIds?: string[]) => {
  store.set(editingDataLayerAtom, undefined);
  store.set(playbackTimeAtom, 0);
  store.set(fixedPlaybackTimeAtom, 0);
  store.set(playbackStatusAtom, "stop");
  store.set(previewTimelineEventIdAtom, undefined);
  store.set(playbackElapsedTimeAtom, 0);

  if (isEmpty(objectIds)) {
    store.set(timelineData_Atom, {});
    store.set(timelineResponseErrorAtom, {});
  }
};

export {
  parseUrl,
  parseTime,
  formatTimelineDates,
  measurePageLoadTime,
  show404Error,
  getObjectIds,
  getViewName,
  validateViewName,
  validateObjectIds,
  restoreInitialMapState,
};
