import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { isEmpty } from "lodash";

import { httpClientAtom } from "atoms/httpclient";
import { timelineOptionsUrlAtom } from "components/map/atoms/timeline/url";

import { OptionsVehicleTimeline } from "types/vehicle-timeline-options";

interface TimelineOptions_ extends OptionsVehicleTimeline {
  optionsUrl?: string;
}

const timelineOptionsAtom = atom<TimelineOptions_ | null>(null);
timelineOptionsAtom.debugLabel = "timelineOptions";

const loadingTimelineOptionsAtom = atom<boolean>(false);
timelineOptionsAtom.debugLabel = "loadingTimelineOptions";

const timelineOptionsEffect = atomEffect((get, set) => {
  const httpClient = get(httpClientAtom);
  const optionsUrl = get(timelineOptionsUrlAtom);

  if (isEmpty(optionsUrl)) {
    return;
  }

  set(loadingTimelineOptionsAtom, true);

  httpClient.options(optionsUrl, {
    successHandler: (res) => {
      set(timelineOptionsAtom, { ...res, optionsUrl });
      set(loadingTimelineOptionsAtom, false);
    },
    errorHandler: () => {
      set(loadingTimelineOptionsAtom, false);
    },
  });
});
timelineOptionsEffect.debugPrivate = true;

export { timelineOptionsEffect, timelineOptionsAtom, loadingTimelineOptionsAtom };
