import { atom } from "jotai";
import { first, isEmpty, last } from "lodash";

import { httpClientAtom } from "atoms/httpclient";
import { userSessionAtom } from "atoms/session";
import {
  isMultiViewAtom,
  mapTabAtom,
  mapTabs,
  mapViewAtom,
  objectIdAtom,
  objectIdsAtom,
  Tab,
  timelineDatesAtom,
} from "components/map/atoms/map";
import { dayTotalsStorageAtom } from "components/map/atoms/timeline/daytotals";
import { timelineActivitiesAtom, timelineEventsAtom } from "components/map/atoms/timeline/settings";
import {
  buildTimelineUrl,
  getChunkedTimelineURL,
  getExceedMAXTimelineDays,
  transformObjectToURLParams,
} from "components/map/atoms/timeline/utils";
import { playbackDateRangeAtom } from "components/map/atoms/vehicle-history";

import { allowedActivitiesForMaxTimelineDays, showDailyTotalsInLiveView } from "components/map/config";

// prevents re-renders by not consuming objects atom in atomEffect
const includeGeometryAtom = atom((get) => {
  return ["playback", "timeline", "multi-timeline", "multi-playback"].includes(get(mapViewAtom) as string);
});
includeGeometryAtom.debugPrivate = true;

const getActivitiesAtom = atom((get) => {
  const timelineDates = get(timelineDatesAtom);
  const activities = get(timelineActivitiesAtom);

  const exceedsAllowedTimelineDays = getExceedMAXTimelineDays(timelineDates);

  if (exceedsAllowedTimelineDays)
    return allowedActivitiesForMaxTimelineDays?.filter((v) => activities?.[v] !== false).toString();

  return transformObjectToURLParams(activities);
});
getActivitiesAtom.debugPrivate = true;

const getEventsAtom = atom((get) => {
  const timelineDates = get(timelineDatesAtom);
  const events = get(timelineEventsAtom);

  const exceedsAllowedTimelineDays = getExceedMAXTimelineDays(timelineDates);

  if (exceedsAllowedTimelineDays) return "";

  return transformObjectToURLParams(events);
});
getEventsAtom.debugPrivate = true;

export const getDayTotalsAtom = atom<string | undefined>((get) => {
  const session = get(userSessionAtom);
  const storeKey = `${session?.accountId}__${session?.userId}`;
  const dayTotals = get(dayTotalsStorageAtom)?.[storeKey];

  if (!dayTotals) {
    return undefined;
  }

  return Object.keys(dayTotals)
    .filter((key) => dayTotals[key].visible)
    .join(",");
});
export const getTimelinePath = (tab?: Tab) => {
  if (tab === mapTabs.VEHICLES) {
    return "vehicle";
  }

  return "driver";
};

const timelineURLBasedOnPlaybackDatesAtom = atom<string>((get) => {
  const httpClient = get(httpClientAtom);
  const activities = transformObjectToURLParams(get(timelineActivitiesAtom));
  const events = transformObjectToURLParams(get(timelineEventsAtom));
  const playbackDateRange = get(playbackDateRangeAtom);
  const timelineDates = get(timelineDatesAtom);
  const includeGeometry = get(includeGeometryAtom);
  const objectIds = get(objectIdsAtom);
  const dayTotals = get(getDayTotalsAtom);
  const tab = getTimelinePath(get(mapTabAtom));
  let results: { [key: string]: string[] | null } = {};
  const loadTimeline = ["playback", "multi-playback"].includes(get(mapViewAtom) as string);

  const exceedsAllowedTimelineDays = getExceedMAXTimelineDays(timelineDates);

  if (
    exceedsAllowedTimelineDays &&
    !isEmpty(playbackDateRange?.startDate) &&
    !isEmpty(playbackDateRange?.endDate) &&
    !isEmpty(objectIds) &&
    loadTimeline
  ) {
    if (objectIds) {
      objectIds.forEach((objectId) => {
        const timelineUrl = buildTimelineUrl(
          new URL(`${httpClient.basePath}${tab}_timeline/?${tab}=${objectId}&skip_parking_days=true`),
          activities,
          events,
          dayTotals,
          includeGeometry
        );

        results[objectId] = getChunkedTimelineURL(
          timelineUrl,
          true,
          playbackDateRange?.startDate,
          playbackDateRange?.endDate
        );
      });
    }
  }

  return JSON.stringify(results);
});
timelineURLBasedOnPlaybackDatesAtom.debugPrivate = true;

const timelineURLsAtom = atom<string>((get) => {
  const timelineDates = get(timelineDatesAtom);
  const activities = get(getActivitiesAtom);
  const events = get(getEventsAtom);
  const httpClient = get(httpClientAtom);
  const tab = getTimelinePath(get(mapTabAtom));
  const includeGeometry = get(includeGeometryAtom);
  const objectIds = get(objectIdsAtom);
  const dayTotals = get(getDayTotalsAtom);
  const loadTimeline = ["live", "timeline", "playback", "multi-timeline", "multi-playback"].includes(
    get(mapViewAtom) as string
  );

  const results: { [key: string]: string[] | null } = {};

  if (!isEmpty(objectIds) && loadTimeline) {
    objectIds?.forEach((objectId) => {
      const timelineUrl = buildTimelineUrl(
        new URL(`${httpClient.basePath}${tab}_timeline/?${tab}=${objectId}&skip_parking_days=true`),
        activities,
        events,
        dayTotals,
        includeGeometry
      );

      results[objectId] = getChunkedTimelineURL(timelineUrl, true, first(timelineDates), last(timelineDates));
    });
  }

  return JSON.stringify(results);
});
timelineURLsAtom.debugLabel = "timelineURL";

const timelineOptionsUrlAtom = atom<string>((get) => {
  const tab = getTimelinePath(get(mapTabAtom));
  const objectId = get(objectIdAtom);
  const loadTimeline = [showDailyTotalsInLiveView && "live", "timeline", "playback", "multi-playback"].includes(
    get(mapViewAtom) as string
  );

  if (get(isMultiViewAtom) && loadTimeline) {
    const accountId = get(userSessionAtom)?.accountId;
    return `/${tab}_timeline/?account=${accountId}`;
  }

  if (objectId && loadTimeline) {
    return `/${tab}_timeline/?${tab}=${objectId}`;
  }

  return "";
});
timelineOptionsUrlAtom.debugLabel = "timelineOptionsUrl";

export { timelineURLsAtom, timelineOptionsUrlAtom, timelineURLBasedOnPlaybackDatesAtom };
