import { t } from "@lingui/macro";
import { message } from "antd";
import { first, isEmpty, split } from "lodash";
import { v4 } from "uuid";

import { dataLayerKey, dataLayerLastUpdatedAtKey } from "components/map/atoms/data-layers";

import { dropDatabase } from "database/db";

import { availableMenus } from "components/navigations/utils";

import { authenticationKeys } from "contexts/app";

import { isUUID } from "utils/uuid-utils";
import { navirecVehicleGroupsIdKey } from "components/map/atoms/vehicles";

const clearStorageKeys = [
  "navirec-timeline-activities",
  "navirec-timeline-events",
  "navirec-timeline-default-activities",
  "navirec-timeline-default-events",
  "navirec-timeline-events-v1",
];

const clearLegacyStorage = () => {
  clearStorageKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};
const onLogout = () => {
  if (typeof localStorage === "undefined") {
    return;
  }

  dropDatabase(() => {
    clearLegacyStorage();
    localStorage.removeItem(authenticationKeys.userId);
    localStorage.removeItem(authenticationKeys.accountId);
    localStorage.removeItem(authenticationKeys.token);
    localStorage.removeItem(authenticationKeys.prevAccountId);
    localStorage.removeItem(authenticationKeys.authorizeToken);
    localStorage.removeItem(dataLayerKey);
    localStorage.removeItem(dataLayerLastUpdatedAtKey);
  });
};

const onChangeAccount = async (key: string) => {
  if (typeof localStorage === "undefined") {
    return;
  }

  const pathname = window.location.pathname;
  const pathSegments = split(pathname, "/").filter((v) => !isEmpty(v));
  const firstPath = pathSegments?.[0];
  const secondPath = pathSegments?.[1];
  let to = firstPath ? `/${firstPath}` : "/";

  if (firstPath && secondPath && !isUUID(secondPath)) {
    to = `${to}/${secondPath}`;
  }

  localStorage.removeItem(authenticationKeys.authorizeToken);
  localStorage.removeItem(navirecVehicleGroupsIdKey);

  if (onGetHijackedSession()) {
    sessionStorage.setItem(authenticationKeys.accountId, key);
    window.location.href = to;
    return;
  }

  localStorage.setItem(authenticationKeys.accountId, key);
  window.location.href = to;
};

// TODO: Refactor this to use Jotai atoms to leverage atomWithStorage subscriptions for automatic updates
const onSetAuthKeys = async (userId?: string, accountId?: string, token?: string) => {
  if (typeof localStorage === "undefined") {
    return;
  }

  if (userId) {
    localStorage.setItem(authenticationKeys.userId, userId);
  }
  if (accountId) {
    localStorage.setItem(authenticationKeys.accountId, accountId);
  }
  if (token) {
    localStorage.setItem(authenticationKeys.token, token);
  }

  clearLegacyStorage();
};

const onGetClientId = () => {
  if (typeof localStorage === "undefined") {
    return v4();
  }

  let clientId = localStorage?.getItem(authenticationKeys.clientId);

  if (clientId) {
    return clientId;
  }

  clientId = v4();
  localStorage?.setItem(authenticationKeys.clientId, clientId);

  return clientId;
};

const onHijackSession = async (token: string, userId: string, accountId: string) => {
  if (typeof sessionStorage === "undefined" || typeof window.location === undefined || !token) {
    return message.error(t`cannot hijack session`);
  }

  sessionStorage.setItem(authenticationKeys.userId, userId);
  sessionStorage.setItem(authenticationKeys.accountId, accountId);
  sessionStorage.setItem(authenticationKeys.token, token);

  window.location.href = "/";
};

const onGetHijackedSession = () => {
  if (typeof sessionStorage === "undefined") {
    console.error(t`cannot fetch hijacked user session`);
    return null;
  }

  const userId = sessionStorage.getItem(authenticationKeys.userId);
  const accountId = sessionStorage.getItem(authenticationKeys.accountId);
  const token = sessionStorage.getItem(authenticationKeys.token);

  if (!userId || !accountId || !token) {
    return null;
  }

  return { userId, accountId, token };
};

// TODO: Refactor this to use Jotai atoms to leverage atomWithStorage subscriptions for automatic updates
const onGetSession = () => {
  if (typeof localStorage === "undefined") {
    message.error(t`cannot fetch user session`);
    return null;
  }

  const userId = localStorage.getItem(authenticationKeys.userId) || undefined;
  const accountId = localStorage.getItem(authenticationKeys.accountId) || undefined;
  const token = localStorage.getItem(authenticationKeys.token) || undefined;
  const language = localStorage.getItem(authenticationKeys.language) || undefined;

  if (!token) {
    return null;
  }

  return { userId, accountId, token, language };
};

const onResetHijackedSessionToken = () => {
  if (typeof sessionStorage === "undefined") {
    message.error(t`cannot hijack user session`);
    return null;
  }

  sessionStorage.removeItem(authenticationKeys.userId);
  sessionStorage.removeItem(authenticationKeys.accountId);
  sessionStorage.removeItem(authenticationKeys.token);
  sessionStorage.removeItem(authenticationKeys.authorizeToken);

  window.location.href = "/";
};

const onRedirectAuthentication = (configFeatures?: { [key: string]: string }) => {
  const path: any = first(availableMenus(configFeatures));

  switch (true) {
    case !isEmpty(path?.children?.[0]?.key):
      return path?.children?.[0]?.key;
    case !isEmpty(path?.key):
      return path?.key;
    default:
      return "/user/settings";
  }
};

const getOrCreateSessionId = () => {
  let sessionId = sessionStorage.getItem(authenticationKeys.sessionId);
  if (!sessionId) {
    sessionId = v4();
    sessionStorage.setItem(authenticationKeys.sessionId, sessionId);
  }

  return sessionId;
};

export {
  onLogout,
  onChangeAccount,
  onSetAuthKeys,
  onGetSession,
  onGetClientId,
  onHijackSession,
  onGetHijackedSession,
  onResetHijackedSessionToken,
  onRedirectAuthentication,
  getOrCreateSessionId,
};
