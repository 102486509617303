import IntervalTree from "@flatten-js/interval-tree";
import dayjs, { Dayjs } from "dayjs";
import { atom, getDefaultStore, useAtom, useAtomValue } from "jotai";
import { atomEffect } from "jotai-effect";
import { first, isEmpty } from "lodash";

import { httpClientAtom } from "atoms/httpclient";
import { mapViewAtom, objectIdAtom, timelineDatesAtom, timelineTimeAtom } from "components/map/atoms/map";
import { onRefreshTimelineData, timelineResponseDateAtom } from "components/map/atoms/timeline/fetch";
import { getTimelineEndDate } from "components/map/atoms/timeline/utils";

import { VehicleHistory } from "types";

const vehicleHistoryAtom = atom<VehicleHistory[]>([]);
vehicleHistoryAtom.debugLabel = "vehicleHistory";

const loadingVehicleHistoryAtom = atom<boolean>(false);
loadingVehicleHistoryAtom.debugLabel = "loadingVehicleHistory";

const vehicleHistoryTreeAtom = atom<any>({});
vehicleHistoryTreeAtom.debugPrivate = true;

export const getPlaybackDates = (playbackDate?: Dayjs | null, timelineDates?: [Dayjs, Dayjs]) => {
  const startPlaybackDate = dayjs.tz(playbackDate)?.startOf("day");
  const endPlaybackDate = dayjs.tz(playbackDate)?.add(1, "day")?.startOf("day");

  const timelineStartDate = timelineDates?.[0];
  const timelineEndDate = timelineDates?.[1];

  const start = timelineStartDate?.isAfter(startPlaybackDate) ? timelineStartDate : startPlaybackDate;
  const end = timelineEndDate?.isBefore(endPlaybackDate) ? timelineEndDate : endPlaybackDate;

  return [start, end];
};
const getVehicleHistoryEndDate = (endDate?: Dayjs, maxDate?: string) => {
  if (!endDate) {
    return endDate;
  }

  const date = dayjs(maxDate);

  return date?.isBefore(endDate) ? date : endDate;
};

const playbackDateRangeAtom = atom((get) => {
  const view = get(mapViewAtom);
  const playbackDate = get(timelineTimeAtom);
  const timelineDates = get(timelineDatesAtom);

  const [startDate, endDate] = getPlaybackDates(playbackDate, timelineDates);

  if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid() || view !== "playback") {
    return undefined;
  }

  return { startDate, endDate };
});
playbackDateRangeAtom.debugPrivate = true;

const vehicleHistoryUrlAtom = atom((get) => {
  const range = get(playbackDateRangeAtom);
  const vehicleID = get(objectIdAtom);
  const maxDate = get(timelineResponseDateAtom);

  if (isEmpty(vehicleID) || isEmpty(range?.startDate) || isEmpty(range?.endDate) || isEmpty(maxDate)) {
    return "";
  }

  const endTime = getVehicleHistoryEndDate(getTimelineEndDate(range?.endDate), maxDate);
  const startTime = range?.startDate;

  if (!startTime?.isValid() || !endTime?.isValid()) {
    return "";
  }

  return `/vehicle_history/?vehicle=${vehicleID}&start_time=${startTime?.toISOString()}&end_time=${endTime?.toISOString()}`;
});
vehicleHistoryUrlAtom.debugLabel = "vehicleHistoryUrl";

const vehicleHistoryEffect = atomEffect((get, set) => {
  const httpClient = get(httpClientAtom);
  const historyUrl = get(vehicleHistoryUrlAtom);

  const vehicleHistoryTree = new IntervalTree();

  if (isEmpty(historyUrl)) {
    return;
  }

  set(loadingVehicleHistoryAtom, true);
  set(vehicleHistoryAtom, []);

  httpClient.get(historyUrl, {
    successHandler: (data) => {
      set(vehicleHistoryAtom, data);
      data.forEach((history: any, index: number) => {
        const nextHistory = data[index + 1];

        let low: number = dayjs(history?.time).unix();
        let high: number = dayjs(nextHistory?.time).unix();

        if (index === 0) {
          low = dayjs(history?.time).startOf("day").unix();
        }

        if (index === data.length - 1) {
          high = dayjs(history?.time).endOf("day").unix();
        }

        vehicleHistoryTree.insert([low, high], history);
      });
      set(vehicleHistoryTreeAtom, vehicleHistoryTree);
      set(loadingVehicleHistoryAtom, false);
    },
    errorHandler: () => {
      set(loadingVehicleHistoryAtom, false);
    },
  });
});

const store = getDefaultStore();

const searchHistoryByTime = (start: number, end?: number, getAll?: boolean): any => {
  const vehicleHistoryTree = store.get(vehicleHistoryTreeAtom);

  if (start && vehicleHistoryTree?.search) {
    const histories = vehicleHistoryTree?.search && vehicleHistoryTree?.search([start, end || start]);
    if (getAll) {
      return histories;
    }

    return first(histories);
  }

  return null;
};

const useVehicleHistory = () => {
  useAtom(vehicleHistoryEffect);

  const vehicleHistory = useAtomValue(vehicleHistoryAtom);
  const loadingHistory = useAtomValue(loadingVehicleHistoryAtom);
  const vehicleHistoryTree = useAtomValue(vehicleHistoryTreeAtom);

  return { vehicleHistory, loadingHistory, vehicleHistoryTree };
};
vehicleHistoryEffect.debugPrivate = true;

const refreshTimelineData = () => {
  const timelineResponseTime = store.get(timelineResponseDateAtom);
  const reload = dayjs(timelineResponseTime).add(1, "minute").isBefore(dayjs());

  if (reload) {
    onRefreshTimelineData();
    return;
  }
};

export {
  useVehicleHistory,
  searchHistoryByTime,
  vehicleHistoryTreeAtom,
  vehicleHistoryAtom,
  loadingVehicleHistoryAtom,
  refreshTimelineData,
  playbackDateRangeAtom,
};
