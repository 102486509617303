const isURLString = (url?: string) => {
  if (!url) {
    return false;
  }

  return url.startsWith("http://") || url.startsWith("https://");
};

const shortenString = (value: string | undefined, limit: number = 7): string => {
  if (!value) return "";
  return value.length > limit ? value.slice(0, limit) : value;
};

const getFirstPathSegment = (url: string) => {
  try {
    const pathname = new URL(url).pathname;
    const segments = pathname.split("/").filter((segment) => segment); // Removes empty segments
    return segments.length > 0 ? segments[0] : null;
  } catch (error) {
    return null;
  }
};

export { isURLString, shortenString, getFirstPathSegment };
