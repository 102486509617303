import axios from "axios";
import { atom } from "jotai";

import { Environment } from "types";

import createHTTPClient from "utils/http-client-utils";
import { httpStatusCodes } from "utils/status-codes-utils";

const NAVIREC_API = process.env.NEXT_PUBLIC_NAVIREC_API;
const environmentURLPathname = "environment/?app=web";
const location: any = typeof window !== "undefined" && window.location ? window.location : "";

interface EnvironmentAtom {
  environment?: Environment;
  error?: any;
}

export const fetchEnvironmentUrl = async (location: Location): Promise<string> => {
  const response = await axios.get<{ [key: string]: string }>(`${location?.origin}/api/config/`);

  if (response.status !== httpStatusCodes.OK) {
    throw new Error(`Failed to fetch config: HTTP ${response.status}`);
  }

  const configData = response.data;
  const currentUrl = new URL(location?.href);
  const currentHost = currentUrl.host;

  let baseUrl = NAVIREC_API;

  if (configData[currentHost]) {
    baseUrl = configData[currentHost];
  } else {
    // Special handling for Vercel preview deployments
    if (currentHost.includes("navirec-next")) {
      if (currentHost.includes("live")) {
        baseUrl = "https://api.navirec.com/";
      } else {
        baseUrl = "https://api.navirec.namespace.ee/";
      }
    }
  }

  // Fallback to pathname-only if no base URL determined
  if (!baseUrl) {
    return environmentURLPathname;
  }

  // Combine base URL with pathname
  return `${baseUrl}${environmentURLPathname}`;
};

const environmentAtom = atom<Promise<EnvironmentAtom | undefined>>(async (get) => {
  const httpClient = createHTTPClient("/", "");

  if (location) {
    const environmentUrl = await fetchEnvironmentUrl(location);

    if (environmentUrl) {
      try {
        const response = await httpClient.get(environmentUrl);
        return {
          environment: response,
          error: null,
        };
      } catch (e) {
        return { environment: undefined, error: e };
      }
    }
  }

  return undefined;
});
environmentAtom.debugLabel = "environment";

export { environmentAtom, location };
