import { startTransition, useCallback, useEffect } from "react";

import { useRouter } from "next/router";

import { t } from "@lingui/macro";
import { Dayjs } from "dayjs";
import { useSetAtom } from "jotai";
import { delay, first, isEmpty } from "lodash";
import nProgress from "nprogress";

import { followVehicleAtom } from "components/map/atoms/follow-vehicle";
import { initializingMapViewAtom, mapStateAtom, MapViewType, Tab } from "components/map/atoms/map";

import {
  formatTimelineDates,
  getObjectIds,
  getViewName,
  measurePageLoadTime,
  parseTime,
  parseUrl,
  restoreInitialMapState,
  show404Error,
  validateObjectIds,
  validateViewName,
} from "components/map/hooks/url/utils";

/**
 * Custom hook for managing map view state based on URL changes.
 * URL structure: /map/[object]/[objectId]/[module(live|playback|timeline)]/?start=[timeline start date]&end=[timeline end date]
 */
const useMapViewRouter = () => {
  const router = useRouter();

  const setAimVehicle = useSetAtom(followVehicleAtom);
  const setMapState = useSetAtom(mapStateAtom);
  const setInitializingMapView = useSetAtom(initializingMapViewAtom);

  /**
   * Handles navigation by parsing URL and updating state.
   * @param path The URL path to navigate to.
   * @param init
   */
  const navigate = (path: string, init = false) => {
    nProgress.start();

    const { segments, query } = parseUrl(path);
    const tab = segments?.[1] || "vehicles"; // Default to 'vehicles' tab
    const view = segments?.[3]; // View type (live, playback, timeline, edit, new)

    const objectIdsInParams = query.get("vehicles") || query.get("drivers") || query.get("areas");
    const eventId = query.get("event") || "";
    const time: Dayjs | undefined = parseTime(query.get("time"));
    const timelineDates = formatTimelineDates(query.get("start"), query.get("end"));
    const objectIds = getObjectIds(objectIdsInParams, segments?.[2]);
    const isMultiView = !isEmpty(objectIdsInParams);
    const viewName = getViewName(view, objectIds?.length as number, isMultiView, segments?.[2], tab);

    // Measure page load time if tab or view changes
    measurePageLoadTime(tab, viewName);

    if (validateObjectIds(objectIds)) {
      show404Error(t`Invalid ${tab} ID.`);
      return;
    }

    if (validateViewName(viewName)) {
      show404Error();
      return;
    }

    // reset playback and
    restoreInitialMapState(objectIds);

    // Set default aim vehicle behavior for playback or live views
    if (["live", "multi-live", "playback"].includes(viewName)) {
      setAimVehicle(true);
    }
    if ("multi-playback" === viewName) {
      setAimVehicle(false);
    }

    setMapState({
      tab: tab as Tab,
      view: viewName as MapViewType,
      isMultiView: isMultiView,
      ids: objectIds,
      timelineDates,
      time: time || first(timelineDates),
      timelineEventId: eventId,
    });

    nProgress.done();

    if (init) {
      delay(() => {
        setInitializingMapView(false);
      }, 1 * 1000);
    }
  };

  useEffect(() => {
    // Set up route change handler
    router.events.on("routeChangeStart", navigate);
    const handler = () => {
      const path = window.location.href;
      navigate(path);
    };
    window.addEventListener("popstate", handler);

    return () => {
      // Clean up route change handler
      router.events.off("routeChangeStart", navigate);
      window.removeEventListener("popstate", handler);
    };
  }, []);

  /**
   * Handles navigation with URL update and state transition.
   * @param url The URL to navigate to.
   * @param pageTitle Optional page title for the history entry.
   */
  const onNavigate = useCallback((url: string, pageTitle: string | undefined = "", init: boolean = false) => {
    startTransition(() => {
      window.history.pushState(window.history.state, pageTitle, url);
      navigate(url, init);
    });
  }, []);

  return { onNavigate };
};

export { useMapViewRouter };
