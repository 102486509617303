import { useLayoutEffect } from "react";

import { Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { atom, useAtomValue, useSetAtom } from "jotai";

import { mapTabAtom, mapViewAtom } from "components/map/atoms/map";

const pageLoadStartAtAtom = atom<Dayjs>(dayjs());
pageLoadStartAtAtom.debugPrivate = true;
const pageLoadEndAtAtom = atom<Dayjs>(dayjs());
pageLoadEndAtAtom.debugPrivate = true;

const MeasurePageLoadEndTime = () => {
  const view = useAtomValue(mapViewAtom);
  const tab = useAtomValue(mapTabAtom);

  const setPageLoadEndTime = useSetAtom(pageLoadEndAtAtom);

  useLayoutEffect(() => {
    setPageLoadEndTime(dayjs());
  }, [view, tab]);

  return null;
};

const PageLoadTime = () => {
  const pageLoadStartTime = useAtomValue(pageLoadStartAtAtom);
  const pageLoadEndTime = useAtomValue(pageLoadEndAtAtom);

  return (
    <Typography.Text ellipsis={true}>{pageLoadEndTime?.diff(pageLoadStartTime, "millisecond")} ms</Typography.Text>
  );
};

export { PageLoadTime, MeasurePageLoadEndTime, pageLoadStartAtAtom, pageLoadEndAtAtom };
