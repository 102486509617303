import { t } from "@lingui/macro";
import { message } from "antd";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomWithStorage } from "jotai/utils";
import { isEmpty, keyBy, trim } from "lodash";

import { httpClientAtom } from "atoms/httpclient";
import { userSessionAtom } from "atoms/session";
import { mapTabAtom, objectIdsAtom } from "components/map/atoms/map";

import { useMapViewRouter } from "components/map/hooks/url";

import { Vehicle } from "types";

const PAGE_SIZE = 1000;
const fetchVehiclesAtom = atom<{ vehicles: Vehicle[]; loading: boolean }>({ vehicles: [], loading: false });
fetchVehiclesAtom.debugPrivate = true;

export const navirecVehicleGroupsIdKey = "navirec-map-vehicle-groups-ids";
const vehicleGroupsIdsAtom = atomWithStorage<string[]>(navirecVehicleGroupsIdKey, [], undefined, {
  getOnInit: true,
});
vehicleGroupsIdsAtom.debugLabel = "vehicleGroupsIds";

const vehiclesSearchTextAtom = atom<string>("");
vehiclesSearchTextAtom.debugLabel = "vehiclesSearchText";

export interface VehicleAtom extends Vehicle {
  hide?: boolean;
}

const vehiclesEffectAtom = atomEffect((get, set) => {
  const httpClient = get(httpClientAtom);
  const session = get(userSessionAtom);

  set(fetchVehiclesAtom, (res) => ({
    ...res,
    loading: true,
  }));

  httpClient.fetchAll(`vehicles/?active=true&account=${session?.accountId}&page_size=${PAGE_SIZE}&display=list`, {
    successHandler: (data) => {
      set(fetchVehiclesAtom, (res) => ({ ...res, vehicles: data, loading: false }));
    },
    errorHandler: () => {
      set(fetchVehiclesAtom, (res) => ({ ...res, loading: false }));
    },
  });
});
vehiclesEffectAtom.debugPrivate = true;

const vehiclesAtom = atom<{ vehicles: VehicleAtom[]; loading: boolean; getVehicleById: (id: string) => VehicleAtom }>(
  (get) => {
    const fetchedVehicles = get(fetchVehiclesAtom);
    const vehicleGroupsIds = get(vehicleGroupsIdsAtom);
    const vehiclesSearchText = get(vehiclesSearchTextAtom);

    let searchText = trim(vehiclesSearchText).toLowerCase();

    let vehicles: VehicleAtom[] = !isEmpty(searchText)
      ? fetchedVehicles?.vehicles?.map((vehicle: Vehicle) => ({
          ...vehicle,
          hide: ![
            vehicle.name,
            vehicle.name_display,
            vehicle.registration,
            vehicle.make,
            vehicle.model,
            vehicle.type,
            vehicle.vin,
          ].some((field) => field?.toLowerCase().includes(searchText)),
        }))
      : fetchedVehicles?.vehicles;

    vehicles = !isEmpty(vehicleGroupsIds)
      ? vehicles?.map((vehicle: Vehicle) => {
          const exist = vehicle.vehicle_groups?.some((group) => {
            return vehicleGroupsIds?.includes(group);
          });
          if (exist) {
            return { ...vehicle };
          }

          return { ...vehicle, hide: true };
        })
      : vehicles;

    const keyedVehicles = keyBy(vehicles, "id");

    return { ...fetchedVehicles, vehicles, getVehicleById: (id: string) => keyedVehicles?.[id] };
  }
);
vehiclesAtom.debugLabel = "vehicles";

const useVehicles = () => {
  const { onNavigate } = useMapViewRouter();
  const tab = useAtomValue(mapTabAtom);

  let vehicles = useAtomValue(vehiclesAtom);
  const objectIds = useAtomValue(objectIdsAtom);

  const setVehicleGroupsIds = useSetAtom(vehicleGroupsIdsAtom);
  const setVehiclesSearchText = useSetAtom(vehiclesSearchTextAtom);

  const onSelectVehicles = (vehicle: string, addToList?: boolean) => {
    let vehicleIds = objectIds ? [...objectIds] : [];

    if (addToList) {
      vehicleIds.push(vehicle);
    } else {
      vehicleIds = vehicleIds.filter((x) => x !== vehicle);
    }

    if (vehicleIds.length > 10) {
      message.open({
        content: t`Multi-vehicle playback is not supported for more than 10 vehicles.`,
        type: "info",
        key: "max-selected-vehicles",
      });
    }

    if (!isEmpty(vehicleIds)) {
      const url = new URL(window.location.href);
      url.searchParams.set("vehicles", vehicleIds?.toString());
      const urlAndParams = `${url.pathname}?${url.searchParams.toString()}`;

      onNavigate(urlAndParams);
    } else {
      onNavigate(`/map/${tab}/`);
    }
  };

  return {
    ...vehicles,
    onSelectVehicles,
    setVehicleGroupsIds,
    setVehiclesSearchText,
  };
};

export { vehiclesEffectAtom, vehiclesAtom, useVehicles, vehicleGroupsIdsAtom, vehiclesSearchTextAtom };
