import dynamic from "next/dynamic";

import { atom, useAtom } from "jotai";
import { isEmpty } from "lodash";

import { EditModes } from "components/deck-gl/layers/editable-geojson";

import { Area } from "types";

const EditableGeoJson = dynamic(() => import("components/deck-gl/layers/editable-geojson"), {
  ssr: false,
});

export interface DataLayerEditor {
  formObject?: Area;
  // featureId represents the ID in which data layer is stored.
  featureId?: string;
  dataLayerId?: string;
  mode?: EditModes;
  features?: any[];
}

export const editingDataLayerAtom = atom<DataLayerEditor | undefined>(undefined);
editingDataLayerAtom.debugLabel = "dataLayerEditor";

const DataLayerEditor = () => {
  const [dataLayer, setDataLayer] = useAtom(editingDataLayerAtom);

  if (isEmpty(dataLayer)) {
    return null;
  }

  return (
    <EditableGeoJson
      id={"edit-geojson-layer"}
      mode={dataLayer?.mode}
      data={{
        type: "FeatureCollection",
        features: dataLayer?.features,
      }}
      onUpdate={(data) => {
        setDataLayer((res) => {
          if (!res?.mode) return res;
          return { ...res, features: data.features };
        });
      }}
    />
  );
};

export default DataLayerEditor;
