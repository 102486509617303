import { createContext, ReactNode, useContext, useEffect, useState } from "react";

import { Locale } from "antd/lib/locale";
import deDE from "antd/lib/locale/de_DE";
import enGB from "antd/lib/locale/en_GB";
import esES from "antd/lib/locale/es_ES";
import etEE from "antd/lib/locale/et_EE";
import fiFI from "antd/lib/locale/fi_FI";
import ltLT from "antd/lib/locale/lt_LT";
import lvLV from "antd/lib/locale/lv_LV";
import nlNL from "antd/lib/locale/nl_NL";
import plPL from "antd/lib/locale/pl_PL";
import ruRU from "antd/lib/locale/ru_RU";

import { messages as deMessages } from "./../locale/de/common";
import { messages as enMessages } from "./../locale/en/common";
import { messages as esMessages } from "./../locale/es/common";
import { messages as etMessages } from "./../locale/et/common";
import { messages as fiMessages } from "./../locale/fi/common";
import { messages as frMessages } from "./../locale/fr/common";
import { messages as ltMessages } from "./../locale/lt/common";
import { messages as lvMessages } from "./../locale/lv/common";
import { messages as nlMessages } from "./../locale/nl/common";
import { messages as plMessages } from "./../locale/pl/common";
import { messages as ruMessages } from "./../locale/ru/common";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/et";
import "dayjs/locale/fi";
import "dayjs/locale/fr";
import "dayjs/locale/lt";
import "dayjs/locale/lv";
import "dayjs/locale/nl";
import "dayjs/locale/pl";
import "dayjs/locale/ru";
import { useAtomValue } from "jotai";

import { languageAtom } from "atoms/language";

import { Session } from "types";

import { initDayJs } from "utils/datetime-utils";

const localeData = require("dayjs/plugin/localeData");
dayjs.extend(localeData);

const defaultLocale = "en";

i18n.load({
  de: deMessages,
  en: enMessages,
  es: esMessages,
  et: etMessages,
  fi: fiMessages,
  lt: ltMessages,
  lv: lvMessages,
  pl: plMessages,
  ru: ruMessages,
  fr: frMessages,
  nl: nlMessages,
});
i18n.activate(defaultLocale); // Set default locale

export const LanguageContext = createContext<{ antdLocale: Locale; locale: string }>({
  antdLocale: { locale: defaultLocale },
  locale: defaultLocale,
});

const useSetAppLanguage = (locale: string, timeZone?: string) => {
  const [antdLocale, setAntdLocale] = useState(enGB);

  dayjs.tz?.setDefault(timeZone); // set dayjs default timezone

  useEffect(() => {
    async function load(locale: string) {
      // const { messages } = await import(`@lingui/loader!./../locale/${locale}/common.po`);

      dayjs.locale(locale);
      dayjs.Ls[locale].weekStart = 1;
      i18n.activate(locale);
    }

    if (locale) {
      load(locale);
      initDayJs(locale);
    }

    switch (locale) {
      case "ee":
      case "et":
        setAntdLocale(etEE);
        break;
      case "de":
        setAntdLocale(deDE);
        break;
      case "es":
        setAntdLocale(esES);
        break;
      case "ru":
        setAntdLocale(ruRU);
        break;
      case "fi":
        setAntdLocale(fiFI);
        break;
      case "pl":
        setAntdLocale(plPL);
        break;
        setAntdLocale(ltLT);
        break;
      case "lv":
        setAntdLocale(lvLV);
        break;
      case "nl":
        setAntdLocale(nlNL);
        break;
    }
  }, [locale]);

  return { antdLocale, locale };
};

const LanguageWrapper = ({ children, session }: { children: ReactNode; session: Session }) => {
  const language = useAtomValue(languageAtom);
  const { antdLocale, locale } = useSetAppLanguage(language || defaultLocale, session?.timezone);

  return (
    <I18nProvider i18n={i18n}>
      <LanguageContext.Provider value={{ antdLocale, locale }}>{children}</LanguageContext.Provider>
    </I18nProvider>
  );
};

export default LanguageWrapper;
const useLocaleContext = () => useContext(LanguageContext);
export { useSetAppLanguage, useLocaleContext, defaultLocale };
