import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { split } from "lodash";
import omit from "lodash/omit";
import { convertArrayToObject } from "utils";

import { userSessionAtom } from "atoms/session";
import { mapTabAtom, objectIdAtom } from "components/map/atoms/map";
import { timelineOptionsAtom } from "components/map/atoms/timeline/options";

import { isUndefinedOrNull } from "components/map/utils";

import { useApplicationContext } from "contexts/app";

const timelineSettingsActivities = "navirec-timeline-activities-v1";
const timelineSettingsEvents = "navirec-timeline-vehicle-events-v1";

type Store = { [key: string]: any };

const timelineActivitiesStorageAtom = atomWithStorage<Store>(timelineSettingsActivities, {}, undefined, {
  getOnInit: true,
});
const timelineEventsStorageAtom = atomWithStorage<Store>(timelineSettingsEvents, {}, undefined, {
  getOnInit: true,
});

const timelineEventsAtom = atom<{ [key: string]: boolean }>((get) => {
  const session = get(userSessionAtom);
  const tab = get(mapTabAtom) as string;
  const objectId = get(objectIdAtom);
  const savedOption = get(timelineEventsStorageAtom);

  const accountId = session?.accountId as string;
  const savedVehicleEvents = savedOption?.[tab]?.[objectId];
  const savedDefaultVehicleEvents = savedOption?.["account"]?.[accountId];

  if (!isUndefinedOrNull(savedVehicleEvents)) return savedVehicleEvents;
  if (!isUndefinedOrNull(savedDefaultVehicleEvents)) return savedDefaultVehicleEvents;

  return undefined;
});

const timelineActivitiesAtom = atom<{ [key: string]: boolean }>((get) => {
  const session = get(userSessionAtom);
  const tab = get(mapTabAtom) as string;
  const objectId = get(objectIdAtom);

  const accountId = session?.accountId as string;
  const savedOption = get(timelineActivitiesStorageAtom);
  const savedVehicleActivities = savedOption?.[tab]?.[objectId];
  const savedDefaultVehicleActivities = savedOption?.["account"]?.[accountId];

  if (!isUndefinedOrNull(savedVehicleActivities)) return savedVehicleActivities;
  if (!isUndefinedOrNull(savedDefaultVehicleActivities)) return savedDefaultVehicleActivities;

  return undefined;
});

const defaultTimelineActivitiesAndEventsAtom = atom<{
  activities: { [key: string]: boolean };
  events: { [key: string]: boolean };
}>((get) => {
  const session = get(userSessionAtom);
  const timelineOptions = get(timelineOptionsAtom);
  const accountId = session?.accountId as string;
  const savedActivitiesOption = get(timelineActivitiesStorageAtom);
  const savedEventsOption = get(timelineEventsStorageAtom);

  const savedDefaultVehicleActivities = savedActivitiesOption?.["account"]?.[accountId];
  const savedDefaultVehicleEvents = savedEventsOption?.["account"]?.[accountId];

  const timelineOptSchema = timelineOptions?.request_schema?.children;
  const defaultActivities = convertArrayToObject(split(timelineOptSchema?.activities?.default || "", ","), true);
  const defaultEvents = convertArrayToObject(split(timelineOptSchema?.vehicle_events?.default || "", ","), true);

  return {
    activities: defaultActivities || savedDefaultVehicleActivities,
    events: defaultEvents || savedDefaultVehicleEvents,
  };
});
defaultTimelineActivitiesAndEventsAtom.debugPrivate = true;

const timelineActivitiesAndEventsAtom = atom<{
  activities: { [key: string]: boolean };
  events: { [key: string]: boolean };
}>((get) => {
  const timelineOptions = get(timelineOptionsAtom);
  const activities = get(timelineActivitiesAtom);
  const events = get(timelineEventsAtom);

  const timelineOptSchema = timelineOptions?.request_schema?.children;
  const defaultActivities = convertArrayToObject(split(timelineOptSchema?.activities?.default || "", ","), true);
  const defaultEvents = convertArrayToObject(split(timelineOptSchema?.vehicle_events?.default || "", ","), true);

  return {
    activities: activities || defaultActivities,
    events: events || defaultEvents,
  };
});
timelineActivitiesAndEventsAtom.debugPrivate = true;

//TODO: refactor timeline settings to be dynamic
const useTimelineSettings = () => {
  const { session } = useApplicationContext();
  const tab = useAtomValue(mapTabAtom) as string;
  const timelineOptions = useAtomValue(timelineOptionsAtom);
  const objectId = useAtomValue(objectIdAtom) as string;

  const accountId = session?.accountId as string;

  const { activities, events } = useAtomValue(timelineActivitiesAndEventsAtom);

  const setTimelineEvents = useSetAtom(timelineEventsStorageAtom);
  const setTimelineActivities = useSetAtom(timelineActivitiesStorageAtom);

  const onUpdateEvents = (values: { [key: string]: boolean }) => {
    setTimelineEvents((res: any) => ({ ...res, [tab]: { [objectId]: { ...res?.[tab]?.[objectId], ...values } } }));
  };

  const onUpdateActivities = (values: { [key: string]: boolean }) => {
    setTimelineActivities((res: Store) => ({
      ...res,
      [tab]: { [objectId]: { ...res?.[tab]?.[objectId], ...values } },
    }));
  };

  const onSetDefaultEvents = (values: { [key: string]: boolean }) => {
    setTimelineEvents((res: Store) => {
      return {
        ...res,
        account: {
          ...res.account,
          [accountId]: values,
        },
      };
    });
  };

  const onSetDefaultActivities = (values: { [key: string]: boolean }) => {
    setTimelineActivities((res: Store) => {
      return {
        ...res,
        account: {
          ...res.account,
          [accountId]: values,
        },
      };
    });
  };

  const onResetTimelineFilters = () => {
    setTimelineEvents((res: Store) => omit(res, `${tab}.${objectId}`));
    setTimelineActivities((res: Store) => omit(res, `${tab}.${objectId}`));
  };

  const timelineOptSchema = timelineOptions?.request_schema?.children;
  const eventsList = timelineOptSchema?.vehicle_events?.choices || [];
  const activitiesList = timelineOptSchema?.activities?.choices || [];

  return {
    eventsList: eventsList,
    activitiesList: activitiesList,
    activities,
    events,
    onResetTimelineFilters,
    onUpdateEvents,
    onUpdateActivities,
    onSetDefaultEvents,
    onSetDefaultActivities,
  };
};

export { useTimelineSettings, timelineActivitiesAtom, timelineEventsAtom, defaultTimelineActivitiesAndEventsAtom };
