import { bearing } from "@turf/turf";
import { getGreatCircleBearing } from "geolib";
import { first, isEmpty } from "lodash";

import { MapIconEvent, TimelineEventsTree } from "components/map/atoms/timeline";

import { isUndefinedOrNullOrNaN, VEHICLE_STATUS, VehicleStatus } from "components/map/utils";

import { resamplePath } from "utils/area-utils";

const calculateHeading = (startCoords: [number, number], endCoords: [number, number]) => {
  return getGreatCircleBearing(
    { latitude: startCoords[1], longitude: startCoords[0] },
    { latitude: endCoords[1], longitude: endCoords[0] }
  );
};

const splitIconEvents = (iconEvents: MapIconEvent[]) => {
  const stopEvent: any[] = [];
  const otherEvent: any[] = [];
  const tankingEvent: any[] = [];

  iconEvents.forEach((iconEvent: MapIconEvent) => {
    switch (true) {
      //TODO: fix openapi spec definition iconEvent?.event.trip?.activity as (activity?: null | null | null | null | null)
      case VEHICLE_STATUS.PARKING === (String(iconEvent?.event.trip?.activity) as VehicleStatus):
        stopEvent.push({
          ...iconEvent,
        });
        break;
      case iconEvent.event.event_type === "tanking":
        const amount = Math.round(Number(iconEvent?.event?.tanking?.amount));
        if (!isUndefinedOrNullOrNaN(amount)) {
          let path = `icons/tanking/added-${amount}.png`;

          if (amount < -1) {
            path = `icons/tanking/removed-${Math.abs(amount)}.png`;
          }

          tankingEvent.push({
            ...iconEvent,
            icon: `${window.location.origin}/${path}`,
          });
        }
        break;
      default:
        otherEvent.push({ ...iconEvent, offset: [0, 0] });
    }
  });

  return { stopEvent, otherEvent, tankingEvent };
};

export interface InterpolatedEvent extends TimelineEventsTree {
  offset: [number, number];
  heading?: number;
}
const getInterpolatedEvent = (timestamp: number, timelineEvent: TimelineEventsTree): InterpolatedEvent | null => {
  const destinationEvent = timelineEvent?.destination;

  if (!timelineEvent) {
    return null;
  }

  if (!destinationEvent) {
    return { ...timelineEvent, offset: [0, 0] };
  }

  let heading = 0;
  const interpolator = (timestamp - timelineEvent.time) / (destinationEvent.time - timelineEvent.time);
  const interpolatedCoordinates: [number, number] = [
    timelineEvent.coordinates[0] * (1 - interpolator) + destinationEvent.coordinates[0] * interpolator,
    timelineEvent.coordinates[1] * (1 - interpolator) + destinationEvent.coordinates[1] * interpolator,
  ];

  if (timelineEvent.vehicleStatus === "driving") {
    heading = calculateHeading(timelineEvent.coordinates, destinationEvent.coordinates);
  }

  return {
    ...timelineEvent,
    heading: heading,
    coordinates: interpolatedCoordinates,
    offset: [0, 0],
  };
};

const mergeExtents = (extents: [number, number, number, number][] | undefined) => {
  const validExtents = extents?.filter((extent) => extent !== null && extent !== undefined);

  if (extents === undefined || isEmpty(extents) || isEmpty(first(extents)) || validExtents?.length === 0) {
    return undefined;
  }

  return extents?.reduce(
    (acc, currentValue) => {
      acc[0] = Math.min(acc[0], currentValue[0]);
      acc[1] = Math.min(acc[1], currentValue[1]);
      acc[2] = Math.max(acc[2], currentValue[2]);
      acc[3] = Math.max(acc[3], currentValue[3]);

      return acc;
    },
    [...extents[0]] as [number, number, number, number]
  );
};

const generateDirectionPoints = (trips: any) => {
  return trips
    .map((trip: any, routesIndex: number) => {
      const paths = trip?.coordinates;
      const timing = trip?.properties?.timing;
      const firstPath = first(trips?.[routesIndex - 1]?.coordinates);
      const resampledPaths = resamplePath(paths, timing, 4);

      return resampledPaths?.map((point: any, index: number) => {
        let heading = 0;
        const bearingCoordinates = point.properties.originalCoordinate || point.geometry.coordinates;
        const time = point.properties.time;
        const coordinates = point?.geometry?.coordinates;
        const nextBearingCoordinates =
          resampledPaths?.[index]?.properties?.bearingCoordinates ||
          resampledPaths?.[index + 1]?.geometry?.coordinates ||
          firstPath;

        if (coordinates && nextBearingCoordinates) {
          heading = bearing(coordinates, nextBearingCoordinates);
        } else {
          // assume bearing last coordinates in line is same as previous one.
          const previousBearingCoordinates = resampledPaths?.[index + 1]?.properties?.originalCoordinate;
          if (previousBearingCoordinates && bearingCoordinates) {
            heading = bearing(previousBearingCoordinates, bearingCoordinates);
          }
        }

        return {
          icon: `${window.location.origin}/map/arrow.png`,
          coordinates,
          heading,
          offset: [0, 0],
          time,
          index,
          tripId: trip?.properties?.id,
        };
      });
    })
    .flat();
};

export { splitIconEvents, generateDirectionPoints, getInterpolatedEvent, mergeExtents, calculateHeading };
