import IntervalTree, { SearchOutput } from "@flatten-js/interval-tree";
import dayjs, { Dayjs } from "dayjs";
import { atom, getDefaultStore, useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { selectAtom } from "jotai/utils";
import { first, flatten, isEmpty, keyBy, last, orderBy } from "lodash";

import { appConfigAtom } from "atoms/configurations";
import {
  mapStateAtom,
  mapViewAtom,
  objectIdAtom,
  timelineEventIdAtom,
  timelineTimeAtom,
} from "components/map/atoms/map";
import { MapSettings, mapSettingsAtom } from "components/map/atoms/settings";
import { dayTotalSettingsAtom, DayTotalsStore } from "components/map/atoms/timeline/daytotals";
import {
  timelineData_Atom,
  timelineDataAtom,
  timelineLoadingAtom,
  timelineResponseErrorAtom,
} from "components/map/atoms/timeline/fetch";
import { dayTotalsLoadingAtom } from "components/map/atoms/timeline/fetch-daytotals";
import { timelineOptionsEffect } from "components/map/atoms/timeline/options";
import { fixedPlaybackTimeAtom, getPlaybackTimeAtom } from "components/map/atoms/timeline/playback";
import { getTripColor, hexToRgb } from "components/map/atoms/timeline/utils";
import { vehiclesAtom } from "components/map/atoms/vehicles";

import { Events } from "components/map/chart/timeline-activity";
import { generateDirectionPoints, getInterpolatedEvent, InterpolatedEvent } from "components/map/timeline/map/utils";
import { mergeDayTotalsSettingsWithValues, VEHICLE_STATUS, VehicleStatus } from "components/map/utils";

import { useApplicationContext } from "contexts/app";

import { TimelineEvent } from "types";

import { DEFAULT_DATE_FORMAT } from "utils/datetime-utils";

export interface ListViewEvent {
  event: TimelineEvent;
  eventIndex: number;
  vehicleStatus: string;
  date?: Dayjs | string;
  parkingNumber?: string | number;
  icon: string;
  color?: string | number[] | undefined;
  location: any; // decide if to remove or not
}

export interface MapIconEvent {
  event: TimelineEvent;
  eventIndex: number;
  coordinates: [number, number];
  time: number;
  parkingNumber: number | undefined;
  icon: string;
  offset: [number, number];
}

export interface ChartLines {
  chart_line_id: string;
  label_text: string;
  event_type: string;
  color?: string;
  data?: Events[];
  vehicle?: string;
}

export interface TimelineWarning {
  key: string;
  description: string;
}

export interface MapTripsEvents {
  coordinates: [number, number][];
  properties: {
    // TODO reivew DS.
    eventIndex: number;
    id: string;
    color: string | undefined | number[];
    timing: [number][];
    event: VehicleStatus;
    icon: string;
    startTime: string;
    endTime: string;
  };
}

export interface TimelineEventsTree {
  event?: TimelineEvent;
  vehicleStatus: VehicleStatus;
  eventIndex: number;
  coordinates: [number, number];
  time: number;
  destination?: {
    coordinates: [number, number];
    time: number;
  };
  icon: string;
  parkingNumber?: number;
}

export interface Timeline {
  startTime?: string;
  mapIconEvents: MapIconEvent[];
  chartLines: ChartLines[];
  mapTripsEvents: { [key: string]: MapTripsEvents[] };
  directionPoints: MapIconEvent[];
  mapSensorsTripsEvents: { [key: string]: MapTripsEvents[] };
  listViewEvents: ListViewEvent[];
  dailyTotals: { [key: string]: DayTotalsStore[] };
  warnings: TimelineWarning[][];
  eventsById: { [key: string]: ListViewEvent };
  timelineEventsTree: IntervalTree<TimelineEventsTree>;
  extents: [number, number, number, number][];
  errors: any;
}

const getActivityIcon = (eventIcon: string, parkingNumber?: number | string) => {
  if (eventIcon === "parking") {
    return `${window.location.origin}/icons/${eventIcon}/${parkingNumber}.png`;
  }
  return `${window.location.origin}/map/${eventIcon}.png`;
};

export const filterEventByDate = (date: Dayjs, event: any) => {
  const eventType = event.event_type;
  const eventData = event?.[eventType];

  switch (eventType) {
    case "trip":
    case "vehicle_event_report":
      const startTime = dayjs(eventData?.start_time);
      const endTime = eventData?.end_time ? dayjs(eventData?.end_time) : dayjs();

      return startTime.isBefore(dayjs.tz(date).endOf("day")) && endTime.isAfter(dayjs.tz(date).startOf("day"));
    default:
      return dayjs.tz(date)?.isSame(event.time, "day");
  }
};

const totalParkingOccurrence = (timelineEvents: any, playbackDate?: Dayjs | null, view?: string) => {
  if (!timelineEvents) {
    return 0;
  }

  return timelineEvents?.reduce((acc: number, currentValue: any) => {
    return (
      acc +
      currentValue?.data?.timeline_events
        ?.filter((event: any) =>
          isEmpty(playbackDate) || view === "timeline" ? true : filterEventByDate(playbackDate, event)
        )
        ?.reduce((evtAcc: number, evtCurrentValue: any) => {
          if (evtCurrentValue?.trip?.activity === "parking") {
            return evtAcc + 1;
          }
          return evtAcc;
        }, 0)
    );
  }, 1);
};

const timelineExtentAtom = atom<[number, number, number, number] | undefined>(undefined);
timelineExtentAtom.debugLabel = "timelineExtent";

const previewTimelineEventIdAtom = atom<string | undefined>(undefined);
previewTimelineEventIdAtom.debugLabel = "previewTimelineEventId";

const scrollToTimelineEventIndexAtom = atom<number | undefined>(undefined);
scrollToTimelineEventIndexAtom.debugLabel = "scrollToTimelineEventIndex";

const searchedTimelineEventsByTimeAtom = atom<SearchOutput<TimelineEventsTree>>((get) => {
  const objectId = get(objectIdAtom);
  const playbackTime = get(getPlaybackTimeAtom);
  const timeline = get(timelineAtom)?.[objectId as string];
  const timelineEvents: SearchOutput<TimelineEventsTree> =
    timeline?.timelineEventsTree?.search && timeline?.timelineEventsTree?.search([playbackTime, playbackTime]);

  return timelineEvents;
});
searchedTimelineEventsByTimeAtom.debugPrivate = true;

export const colorTripsSelector = (v: MapSettings) => v.colorTrips;

const timelineAtom = atom<{ [key: string]: Timeline }>((get) => {
  const view = get(mapViewAtom);
  const { getVehicleById } = get(vehiclesAtom);
  const colorTripsSettings = get(selectAtom(mapSettingsAtom, colorTripsSelector));
  const playbackDate = get(timelineTimeAtom);
  const configuration = get(appConfigAtom);
  const timeZone = configuration?.account?.timezone;
  const showParkingNumbers = ["timeline", "playback", "multi-timeline", "multi-playback"]?.includes(view);
  const keyedTimelineData = get(timelineDataAtom);

  const results: { [key: string]: Timeline } = {};

  Object.keys(keyedTimelineData).map((key: string) => {
    const timelineData = orderBy(keyedTimelineData[key] || [], ["data.start_time"], ["desc"]);
    let eventIndex: number = 0;

    const extents: [number, number, number, number][] = [];
    const mapIconEvents: MapIconEvent[] = [];
    const mapTripsEvents: any = {};
    const mapSensorsTripsEvents: any = {};
    const warnings: TimelineWarning[][] = [];
    const listViewEvents: ListViewEvent[] = [];
    const dailyTotals: { [key: string]: DayTotalsStore[] } = {};
    const timelineEventsTree = new IntervalTree<TimelineEventsTree>();
    const startTime = first(timelineData)?.data?.start_time;

    let countParkingNumber: number = totalParkingOccurrence(timelineData, playbackDate, view);
    const orderedTimelineData = orderBy(timelineData || [], ["data.start_time"], ["desc"]);

    const lines = last(orderedTimelineData)?.data?.chart_lines || [{}];
    const chartLines = lines?.map((lines: { [x: string]: string }) => {
      const vehicle = getVehicleById(key);
      lines["vehicle"] = (vehicle?.name_display || vehicle?.name) as string;
      return lines;
    });

    timelineData?.forEach((events, index) => {
      const start = dayjs.tz(dayjs(events?.data?.start_time));
      const end = dayjs.tz(dayjs(events?.data?.end_time));

      const extent = events?.data?.extent;
      const dayTotalsData = events?.data?.totals;
      warnings[index] = events?.data?.warnings;

      const timelineEvents = events?.data?.timeline_events?.filter((event: any) =>
        isEmpty(playbackDate) || ["timeline", "multi-timeline"].includes(view)
          ? true
          : filterEventByDate(playbackDate, event)
      );

      if (extent) {
        extents.push(extent);
      }

      if (!isEmpty(dayTotalsData) && start.isSame(end?.subtract(1, "second"), "day")) {
        dailyTotals[start.format(DEFAULT_DATE_FORMAT)] = mergeDayTotalsSettingsWithValues(
          dayTotalsData,
          get(dayTotalSettingsAtom)
        );
      }

      timelineEvents?.forEach((event: TimelineEvent) => {
        const timelineEventData = (event as any)?.[event?.event_type];
        const vehicleStatus = timelineEventData?.activity || timelineEventData?.event || event.event_type;
        const isTrip = event?.event_type === "trip";

        const startTime = dayjs(timelineEventData.start_time).unix();
        const endTime = dayjs(timelineEventData.end_time || undefined).unix();
        const eventIcon = event?.icon || timelineEventData?.activity || timelineEventData?.event;
        const eventLocation = event?.location?.coordinates || timelineEventData?.end_location?.coordinates;
        const isMapDisplayGeometry = event?.map_display?.includes("geometry");
        const isMapDisplayIcon = event?.map_display?.includes("icon");
        const tripColor = (asHex?: boolean, generateColor?: boolean) => {
          if (generateColor) {
            if (event.color) {
              return asHex ? event?.color : hexToRgb(event?.color);
            }
            return getTripColor(timelineEventData.id, colorTripsSettings as boolean, asHex);
          }

          return undefined;
        };

        if (vehicleStatus === "parking") {
          countParkingNumber--;
        }

        const parkingNumber = vehicleStatus === "parking" && showParkingNumbers ? countParkingNumber : undefined;

        //populate icons events
        if (isMapDisplayIcon) {
          const parkingIconOffset: [number, number] = [1, 1];
          mapIconEvents.push({
            eventIndex,
            event,
            coordinates: eventLocation,
            time: dayjs(event.time).unix(),
            parkingNumber: parkingNumber,
            icon: getActivityIcon(vehicleStatus, parkingNumber),
            offset: parkingNumber ? parkingIconOffset : [0, -1],
          });
        }

        // populate feature collection for trips
        if (isMapDisplayGeometry) {
          const geometry = event?.geometry as any;
          const tripTiming = event.timing as any;

          switch (geometry?.type) {
            case "MultiLineString":
              const tripsData = geometry?.coordinates?.map(
                (coordinates: [number, number][], coordinatesIndex: number) => {
                  const timing = tripTiming?.[coordinatesIndex]?.map((t: string, timingIndex: number) => {
                    const time = dayjs(t).unix();
                    const endTime =
                      tripTiming?.[coordinatesIndex]?.[timingIndex + 1] || tripTiming?.[coordinatesIndex + 1]?.[0] || t;
                    const endTimeInUnix = dayjs(endTime).unix();
                    const destCoordinates =
                      coordinates[timingIndex + 1] ||
                      geometry?.coordinates?.[coordinatesIndex + 1]?.[0] ||
                      coordinates[timingIndex];

                    if (time && endTimeInUnix && isTrip) {
                      timelineEventsTree?.insert([time, endTimeInUnix], {
                        event,
                        eventIndex,
                        time,
                        vehicleStatus: vehicleStatus,
                        coordinates: coordinates[timingIndex],
                        destination: {
                          coordinates: destCoordinates,
                          time: endTimeInUnix,
                        },
                        icon: getActivityIcon(vehicleStatus, parkingNumber),
                      });
                    }

                    return time;
                  });

                  return {
                    coordinates: coordinates,
                    properties: {
                      eventIndex,
                      id: timelineEventData.id,
                      color: tripColor(false, true),
                      timing: timing,
                      event: vehicleStatus,
                      icon: eventIcon,
                      startTime: timelineEventData?.start_time,
                      endTime: timelineEventData?.end_time,
                    },
                  };
                }
              );

              if (isTrip) {
                mapTripsEvents[timelineEventData.id] = tripsData;
              } else {
                mapSensorsTripsEvents[timelineEventData.id] = tripsData;
              }
              break;
            default:
            //do nothing
          }
        }

        // populate listview events
        const generateColor = isMapDisplayGeometry && (["driving", "towing"].includes(vehicleStatus) || !isTrip);
        listViewEvents.push({
          eventIndex,
          vehicleStatus,
          event,
          date: dayjs(event.time).tz(timeZone).format(DEFAULT_DATE_FORMAT),
          parkingNumber: parkingNumber,
          [event?.event_type]: timelineEventData,
          icon: eventIcon,
          color: tripColor(true, generateColor),
          location: isTrip ? timelineEventData?.start_location : event.location,
        });

        if (isTrip && startTime && endTime && isMapDisplayIcon && eventLocation) {
          timelineEventsTree?.insert([startTime, endTime], {
            event,
            eventIndex,
            time: dayjs(event.time).unix(),
            vehicleStatus: vehicleStatus,
            coordinates: eventLocation,
            parkingNumber: parkingNumber,
            icon: getActivityIcon(vehicleStatus, parkingNumber),
          });
        }
        eventIndex++;
      });
    });

    results[key] = {
      startTime,
      chartLines,
      warnings,
      mapIconEvents,
      mapTripsEvents,
      directionPoints: generateDirectionPoints(flatten(Object.values(mapTripsEvents))),
      mapSensorsTripsEvents,
      listViewEvents,
      dailyTotals,
      timelineEventsTree,
      extents,
      errors: get(timelineResponseErrorAtom)[key],
      eventsById: keyBy(
        listViewEvents,
        (listViewEvent) => (listViewEvent?.event as any)?.[listViewEvent?.event?.event_type]?.id as string
      ),
    };
  });

  return results;
});

const eventByIdSelector =
  (eventId?: string) =>
  (keyedTimeline: { [key: string]: Timeline }): ListViewEvent | undefined => {
    if (!eventId) return undefined;

    for (const timeline of Object.values(keyedTimeline)) {
      const event = timeline.eventsById[eventId];
      if (event) {
        return event;
      }
    }

    return undefined;
  };

const focusEventOnMapEffect = atomEffect((get, set) => {
  const eventId = get(timelineEventIdAtom);
  const timelineEvent = get(selectAtom(timelineAtom, eventByIdSelector(eventId)));

  if (timelineEvent?.event?.extent) {
    set(scrollToTimelineEventIndexAtom, timelineEvent?.eventIndex);
    set(timelineExtentAtom, timelineEvent?.event?.extent as [number, number, number, number]);
  }
  if (timelineEvent?.event?.time) {
    set(fixedPlaybackTimeAtom, dayjs(timelineEvent?.event?.time).unix() + 1);
  }
});

const treeSelector = (objectId?: string) => (v: { [key: string]: Timeline }) =>
  v?.[objectId as string]?.timelineEventsTree;

const store = getDefaultStore();
const scrollToEventByTime = (playbackTime?: number, objectId?: string) => {
  const goToEvent = () => {
    if (playbackTime) {
      store.set(scrollToTimelineEventIndexAtom, (prevIndex) => {
        const tree = store.get(selectAtom(timelineAtom, treeSelector(objectId)));
        if (!tree) {
          return prevIndex;
        }
        const event = last(tree?.search([playbackTime, playbackTime]));
        return event?.eventIndex || 0;
      });

      store.set(timelineExtentAtom, (prevExtent) => {
        const tree = store.get(selectAtom(timelineAtom, treeSelector(objectId)));
        if (!tree) {
          return prevExtent;
        }
        const event = last(tree?.search([playbackTime, playbackTime]));
        return event?.event?.extent;
      });
    }
  };

  goToEvent();
};

timelineAtom.debugLabel = "timeline";

const getLatestActivity = () => {
  const timeline = store.get(timelineAtom);
  const objectId = store.get(objectIdAtom);

  return objectId && timeline?.startTime ? dayjs(timeline[objectId as string]?.startTime) : dayjs.tz();
};

const useTimeline = () => {
  const { httpClient } = useApplicationContext();
  useAtom(timelineOptionsEffect);

  const [timelineData, setTimelineData] = useAtom(timelineData_Atom);
  const [timelineLoading, setTimelineLoading] = useAtom(timelineLoadingAtom);

  const dayTotalsLoading = useAtomValue(dayTotalsLoadingAtom);
  const timeline = useAtomValue(timelineAtom);
  const objectId = useAtomValue(objectIdAtom);
  const setMapState = useSetAtom(mapStateAtom);

  const onFetchNext = () => {
    if (timelineLoading || !objectId) {
      return;
    }

    const lastRecord: any = last(timelineData[objectId]);
    const nextURL = lastRecord?.cursors?.prev?.url;

    if (nextURL) {
      setTimelineLoading(true);
      httpClient
        .swrInfiniteFetcher(nextURL)
        .then((data: any) => {
          setTimelineData((res: { [key: string]: any[] }) => {
            return { ...res, [objectId]: res[objectId].concat(data) };
          });
          setTimelineLoading(false);
        })
        .catch(() => {
          setTimelineLoading(false);
        });
    }
  };

  const getCurrentEvent = (
    playbackTime: number,
    ignoreParkingEvent: boolean = false,
    objectId: string
  ): InterpolatedEvent | null => {
    if (objectId) {
      const events: SearchOutput<TimelineEventsTree> = timeline[objectId]?.timelineEventsTree?.search([
        playbackTime,
        playbackTime,
      ]);
      const closestVehicleInTime = events && last(events);

      if (closestVehicleInTime?.vehicleStatus === VEHICLE_STATUS.PARKING && ignoreParkingEvent) {
        return null;
      }

      return getInterpolatedEvent(playbackTime, closestVehicleInTime);
    }

    return null;
  };

  const onShowTimelineEvent = (eventId: string) => {
    if (eventId) {
      setMapState((res) => ({ ...res, timelineEventId: eventId }));
    }
  };

  const getEventById = (eventsId: string, asIconData: boolean = false, objectId: string) => {
    if (!objectId) {
      return undefined;
    }
    const event = timeline?.[objectId]?.eventsById?.[eventsId as string];

    if (isEmpty(event)) {
      return undefined;
    }

    if (asIconData) {
      return {
        ...event,
        coordinates: event?.location?.coordinates,
        icon: getActivityIcon(event.vehicleStatus, event.parkingNumber),
        text: `${event.parkingNumber}`,
        textOffset: [0, 0],
        textColor: [255, 255, 255],
        textAnchor: "middle",
      };
    }

    return event;
  };

  return {
    keyedTimeline: timeline,
    timelineLoading: timelineLoading || dayTotalsLoading,
    onFetchNext,
    getCurrentEvent,
    onShowTimelineEvent,
    getEventById,
  };
};

export {
  useTimeline,
  timelineExtentAtom,
  searchedTimelineEventsByTimeAtom,
  timelineAtom,
  focusEventOnMapEffect,
  scrollToTimelineEventIndexAtom,
  previewTimelineEventIdAtom,
  scrollToEventByTime,
  getLatestActivity,
};
