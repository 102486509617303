const convertNumberToAlphabet = (index: number) => {
  return String.fromCharCode(index + "A".charCodeAt(0));
};

const convertArrayToObject = (arr: string[], defaultValue: any) => {
  return arr.reduce(function (acc: { [key: string]: any }, cur, _) {
    acc[cur] = defaultValue;
    return acc;
  }, {});
};

const multiPartition = <T>(collection: T[], predicates: Array<(item: T) => boolean | undefined>): T[][] => {
  // Initialize empty groups for each predicate plus one for unmatched items
  const groups: T[][] = [...predicates.map(() => []), []];

  // Iterate over the collection and test each item against all predicates
  for (const item of collection) {
    let matched = false;

    for (let i = 0; i < predicates.length; i++) {
      if (predicates[i](item)) {
        groups[i].push(item);
        matched = true;
        break; // Exit the inner loop after the first match
      }
    }

    // If no predicate matched, add the item to the last group
    if (!matched) {
      groups[groups.length - 1].push(item);
    }
  }

  return groups;
};

export { convertNumberToAlphabet, convertArrayToObject, multiPartition };
