import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { isEmpty, split } from "lodash";

import { userSessionAtom } from "atoms/session";
import { mapViewAtom } from "components/map/atoms/map";
import { timelineOptionsAtom } from "components/map/atoms/timeline/options";

import { isUndefinedOrNull } from "components/map/utils";

import { useApplicationContext } from "contexts/app";

const dayTotalsSettingsKey = "navirec-day-totals-settings";
export interface DayTotalsSettings {
  label: string;
  key: string;
  visible?: boolean;
  icon: string;
  type: string;
  unit: string;
  position?: number;
}

export type DayTotalsStore = { [key: string]: DayTotalsSettings };
export const dayTotalsStorageAtom = atomWithStorage<{ [key: string]: DayTotalsStore | undefined }>(
  dayTotalsSettingsKey,
  {},
  undefined,
  {
    getOnInit: true,
  }
);

const dayTotalSettingsAtom = atom<{ [key: string]: DayTotalsSettings }>((get) => {
  const view = get(mapViewAtom);
  const session = get(userSessionAtom);
  const storeKey = `${session?.accountId}__${session?.userId}`;
  const timelineOptions = get(timelineOptionsAtom);
  const savedDayTotals = get(dayTotalsStorageAtom)?.[storeKey];

  if (isEmpty(timelineOptions) || ["list"].includes(view as string)) {
    return {};
  }

  const orderedSettings: { [key: string]: DayTotalsSettings } = {};
  const timelineOptSchema = timelineOptions?.request_schema?.children;
  const defaultDayTotals = split(timelineOptSchema?.totals?.default || "", ",");
  const dayTotalsChoices = timelineOptSchema?.totals?.choices;
  const totalsTypeAndUnit = timelineOptions?.response_schema?.children?.totals?.children as { [key: string]: any };

  dayTotalsChoices?.forEach((choice: any, index: number) => {
    const choiceValue = choice?.value;
    let defaultPos = index;
    const position = savedDayTotals?.[choiceValue]?.position;
    const isVisible = savedDayTotals?.[choiceValue]?.visible;

    if (choiceValue) {
      orderedSettings[choiceValue] = {
        ...totalsTypeAndUnit?.[choiceValue],
        ...savedDayTotals?.[choiceValue],
        key: choiceValue,
        label: choice.display_name,
        icon: choiceValue,
        position: isUndefinedOrNull(position) ? defaultPos : position,
        visible: isUndefinedOrNull(isVisible) ? defaultDayTotals?.includes(choiceValue) : isVisible,
      };
    }
  });

  return { ...orderedSettings };
});

const timelineTotalsAtom = atom<
  | {
      totals: DayTotalsStore[];
      dailyTotals: { [key: string]: DayTotalsStore[] };
    }
  | undefined
>();

const useDayTotalSettings = () => {
  const { session } = useApplicationContext();
  const storeKey = `${session?.accountId}__${session?.userId}`;

  const dayTotals = useAtomValue(dayTotalSettingsAtom);
  const setDayTotalsStorage = useSetAtom(dayTotalsStorageAtom);

  const onUpdateDayTotalsSettings = (settings: { [key: string]: DayTotalsSettings }) => {
    setDayTotalsStorage((res) => {
      return {
        ...res,
        [storeKey]: {
          ...res?.[storeKey],
          ...settings,
        },
      };
    });
  };

  const dayTotalsKeys = Object.keys(dayTotals || {}).filter((key) => dayTotals[key].visible);

  const onResetDayTotals = () => {
    setDayTotalsStorage((res) => {
      delete res?.[storeKey];
      return { ...res };
    });
  };

  return { dayTotalsKeys, dayTotals, onUpdateDayTotalsSettings, onResetDayTotals };
};

export { useDayTotalSettings, dayTotalSettingsAtom, timelineTotalsAtom };
