import { SearchOutput } from "@flatten-js/interval-tree";
import { atom, getDefaultStore, useAtom, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { selectAtom } from "jotai/utils";
import { first, last } from "lodash";
import { animate, linear, progress } from "popmotion";

import { mapStateAtom, timelineDatesAtom, timelineTimeAtom } from "components/map/atoms/map";
import { MapSettings, mapSettingsAtom } from "components/map/atoms/settings";
import { timelineAtom, TimelineEventsTree } from "components/map/atoms/timeline";
import { chartTimeRangeAtom } from "components/map/atoms/timeline/chart-time";
import { loadingVehicleHistoryAtom } from "components/map/atoms/vehicle-history";

import { VEHICLE_STATUS } from "components/map/utils";

export const playbackSpeedSelector = (v: MapSettings) => v.playbackDuration;
export const showFullDaySelector = (v: MapSettings) => v.showFullDay;

const fixedPlaybackTimeAtom = atom<number>(0);
const playbackTimeAtom = atom<number>(0);

const getPlaybackTimeAtom = atom((get) => {
  return get(fixedPlaybackTimeAtom) || get(playbackTimeAtom);
});
getPlaybackTimeAtom.debugPrivate = true;

const playbackElapsedTimeAtom = atom(0);
const playbackStatusAtom = atom<"play" | "pause" | "resume" | "stop">("stop");

const isParkingAtom = atom<boolean>(false);
isParkingAtom.debugPrivate = true;

const skipParkingEffectAtom = atomEffect((get, set) => {
  const playbackStatus = get(playbackStatusAtom);

  if (playbackStatus === "play" || playbackStatus === "resume") {
    const playbackTime = get(fixedPlaybackTimeAtom);
    const keyedTimeline = get(timelineAtom);

    // Iterate over all timelines in keyedTimeline
    const isParkingOrOffline = Object.values(keyedTimeline).every((timeline) => {
      if (timeline?.timelineEventsTree?.search) {
        const timelineEvents: SearchOutput<TimelineEventsTree> = timeline.timelineEventsTree.search([
          playbackTime,
          playbackTime,
        ]);
        const timelineEvent = timelineEvents && last(timelineEvents);
        return [VEHICLE_STATUS.PARKING, VEHICLE_STATUS.OFFLINE, null, undefined].includes(timelineEvent?.vehicleStatus);
      }

      return true;
    });

    set(isParkingAtom, isParkingOrOffline);
  }
});
skipParkingEffectAtom.debugPrivate = true;

const playbackEffectAtom = atomEffect((get, set) => {
  get(skipParkingEffectAtom);

  const autoplay = get(playbackStatusAtom);
  const playbackDuration = get(selectAtom(mapSettingsAtom, playbackSpeedSelector)) || 5;
  const [timelineStartTime, timelineEndTime] = get.peek(chartTimeRangeAtom);
  const elapsedTime = get(playbackElapsedTimeAtom);
  const loadingVehicleHistory = get(loadingVehicleHistoryAtom);
  const isParking = get(isParkingAtom);

  if (loadingVehicleHistory || !playbackDuration) {
    return;
  }

  const from = timelineStartTime?.unix();
  const to = timelineEndTime?.unix();
  const duration = playbackDuration * (isParking ? 3 : 60) * 1000;
  const elapsed = Math.max(0, duration * elapsedTime);

  const handler = animate<number>({
    from,
    to,
    duration,
    autoplay: autoplay === "play" || autoplay === "resume",
    elapsed: elapsed,
    type: "keyframes",
    ease: linear,
    onUpdate: (() => {
      let lastUpdate = 0;

      return (e: number | ((prev: number) => number)) => {
        const now = Date.now();
        if (now - lastUpdate >= 30) {
          set.recurse(fixedPlaybackTimeAtom, e);
          lastUpdate = now;
        }
      };
    })(),
    onComplete: () => {
      const playbackDate = get.peek(timelineTimeAtom);
      const timelineDates = get.peek(timelineDatesAtom);

      const autoPlayNextDay = playbackDate?.add(1, "day")?.startOf("day");
      const isInRangeOfTimelineRange = autoPlayNextDay?.isBetween(
        first(timelineDates),
        last(timelineDates),
        "day",
        "[]"
      );

      set(playbackElapsedTimeAtom, 0);
      set(fixedPlaybackTimeAtom, 0);

      if (isInRangeOfTimelineRange && autoPlayNextDay) {
        set.recurse(mapStateAtom, (state) => ({
          ...state,
          time: autoPlayNextDay,
        }));
      } else {
        set.recurse(playbackStatusAtom, "stop");
      }
    },
  });

  return () => {
    if (autoplay === "play" || autoplay === "resume") {
      handler?.stop();
    }

    const playbackTime = get(fixedPlaybackTimeAtom);
    if (playbackTime && from && to) {
      const elapsed = progress(from, to, playbackTime);
      set(playbackElapsedTimeAtom, elapsed);
    }
  };
});

playbackEffectAtom.debugPrivate = true;
fixedPlaybackTimeAtom.debugLabel = "fixedPlaybackTime";
playbackTimeAtom.debugLabel = "playbackTime";
playbackElapsedTimeAtom.debugLabel = "playbackElapsedTime";
playbackStatusAtom.debugLabel = "playbackStatus";

const usePlayback = () => {
  const setMapState = useSetAtom(mapStateAtom);

  useAtom(playbackEffectAtom);

  const [playbackStatus, setStartPlayback] = useAtom(playbackStatusAtom);

  const startPlayback = () => {
    setMapState((res) => ({ ...res, timelineEventId: "" }));
    setStartPlayback("play");
  };

  const stopPlayback = () => {
    setStartPlayback("stop");
  };

  const pausePlayback = () => {
    setStartPlayback("pause");
  };

  const resumePlayback = () => {
    setMapState((res) => ({ ...res, timelineEventId: "" }));
    setStartPlayback("resume");
  };

  return { playbackStatus, startPlayback, stopPlayback, pausePlayback, resumePlayback };
};

const store = getDefaultStore();

const pausePlayback = () => {
  const playbackStatus = store.get(playbackStatusAtom);

  if (playbackStatus === "play" || playbackStatus === "resume") {
    store.set(playbackStatusAtom, "pause");
  }
};

export {
  usePlayback,
  pausePlayback,
  playbackEffectAtom,
  playbackStatusAtom,
  playbackTimeAtom,
  fixedPlaybackTimeAtom,
  playbackElapsedTimeAtom,
  getPlaybackTimeAtom,
};
