import ColorScale from "color-scales";
import colorString from "color-string";
import objectHash from "object-hash";
import randomColor from "randomcolor";

const newColorScale = new ColorScale(
  0,
  10,
  ["#FA0A0A", "#FF5457", "#EA723B", "#FFAB00", "#CFBF68", "#A2CF68", "#38D379"],
  1
);

const getEcoScoreColor = (scale: number) => {
  return newColorScale.getColor(scale).toHexString();
};

const generateRandomColor = (
  seed: string,
  luminosity: "bright" | "light" | "dark" | "random" | undefined = "bright"
) => {
  return randomColor({
    luminosity: luminosity,
    seed: objectHash({ seed }),
  });
};

const nameHexColorCode = (color: number[]) => {
  return colorString.to.keyword(color);
};

export { getEcoScoreColor, generateRandomColor, nameHexColorCode };
