import { WebMercatorViewport } from "@deck.gl/core";
import {
  area as turfArea,
  distance,
  Feature,
  featureCollection,
  FeatureCollection,
  kinks,
  length as turfLength,
  lineString,
  mask as turfMask,
  multiPolygon,
  nearestPoint,
  Point,
  point,
  polygon,
} from "@turf/turf";
import circleToPolygon from "circle-to-polygon";
import colorString from "color-string";
import { isEmpty, isEqual, last, round, trim } from "lodash";

import { defaultShapeColor } from "components/map/config";

import { turfAlongWithTime } from "utils/turf-utils";

type RGBAColor = [number, number, number, number?];

const getTotalArea = (feature: Feature) => {
  if (feature?.geometry?.type === "MultiPolygon") {
    return turfArea(multiPolygon(feature?.geometry?.coordinates as any));
  }

  if (feature?.geometry?.type === "Polygon") {
    return turfArea(polygon(feature?.geometry?.coordinates as any));
  }

  return 0;
};

const getFeaturesTotalArea = (features?: Feature[]) => {
  return features?.reduce((acc, feature) => {
    return acc + getTotalArea(feature);
  }, 0);
};

const diffAreaObject = (updated: any, initial: any) => {
  const diff: any = {};

  Object.keys(updated).forEach((key) => {
    if (!isEqual(updated[key], initial[key])) {
      diff[key] = updated[key];
    }
  });

  return diff;
};

// Function to combine multiple GeoJSON features and Feature Collections into a single MultiPolygon
const combineFeaturesIntoMultiPolygon = (features: any) => {
  const polygons: any = [];

  // Extract polygons from GeoJSON features and merge them into one array
  const extractor = (data: any) => {
    data?.forEach((feature: any) => {
      // convert circle to polygons
      if (feature?.geometry && feature?.properties?.radius) {
        const c = circleToPolygon(feature?.geometry?.coordinates, feature.properties.radius, 24);
        polygons.push(c.coordinates);
      }
      if (feature?.geometry && feature?.geometry?.type === "Polygon") {
        polygons.push(feature.geometry.coordinates);
      }
      if (feature?.geometry && feature?.geometry?.type === "MultiPolygon") {
        polygons.push(...feature.geometry.coordinates);
      }
    });
  };

  extractor(features);

  return multiPolygon(polygons);
};

const filterAreasListByGroupId = (areas: any[], groupsId: string[]) => {
  return areas?.filter((area: any) => {
    if (isEmpty(groupsId)) {
      return true;
    }

    return groupsId.some((id: string) => {
      return area?.properties?.area_group_ids.includes(id);
    });
  });
};

const filterAreasListByName = (areas: any[] = [], search: string) => {
  return areas?.filter((area: any) => {
    if (!search) {
      return true;
    }
    return area?.properties?.name_display.toLowerCase().includes(trim(search)?.toLowerCase());
  });
};

const buildAreasUrl = (basePath: string | undefined, areaId: string) => {
  if (!areaId) {
    return null;
  }
  return `${basePath}areas/${areaId}/`;
};

const getLayerColor = (layerColor?: string) => {
  let color: RGBAColor = colorString.get.rgb(layerColor as string);

  if (isEmpty(color)) {
    return defaultShapeColor.RGBA as RGBAColor;
  }

  color[color.length - 1] = Number(last(color)) * 255;

  return color;
};

const getLayerBorderColor = (borderColor: string) => {
  let color: RGBAColor = colorString.get.rgb(borderColor);

  if (isEmpty(color)) {
    return defaultShapeColor.RGBA as RGBAColor;
  }

  color?.pop();

  return color;
};

const createPolygonWithMask = (polygon: Feature<any>, mask: Feature<any>) => {
  return turfMask(polygon, mask);
};

const selfIntersectingFeatureCollection = (featureCollection: FeatureCollection) => {
  let intersect = false;

  featureCollection.features.forEach((feature: any) => {
    const isPoint = feature?.geometry?.type === "Point";
    if (!isPoint && !isEmpty(kinks(feature)?.features)) {
      intersect = true;
    }
  });

  return intersect;
};

const getBBoxFromZoomAndCenter = (longitude: number, latitude: number, zoom?: number) => {
  const viewport = new WebMercatorViewport({
    width: window.innerWidth,
    height: window.innerHeight,
    longitude,
    latitude,
    zoom: zoom,
  });
  //@ts-ignore
  return viewport?.getBounds()?.map((p) => round(p, 6)) as [number, number, number, number];
};

const getBBoxFromPoints = (points: [number, number][]): any => {
  const n = points.length;
  if (n === 0) {
    return [];
  }

  const d = points[0].length;
  const lo = points[0].slice();
  const hi = points[0].slice();

  for (let i = 1; i < n; ++i) {
    const p = points[i];

    for (let j = 0; j < d; ++j) {
      const x = p[j];
      lo[j] = Math.min(lo[j], x);
      hi[j] = Math.max(hi[j], x);
    }
  }

  return [lo, hi].flat();
};

const getInterpolatedTimeFromLines = (coordinate: [number, number], lines: [number, number][], properties: any[]) => {
  if (isEmpty(coordinate) || isEmpty(lines)) {
    return undefined;
  }

  const targetPoint = point(coordinate);
  const points = featureCollection(
    lines.map((pt, index) => {
      return point(pt, properties?.[index]);
    })
  );

  const np = nearestPoint(targetPoint, points);
  const distanceToPoint = np.properties.distanceToPoint;
  const from = np.geometry.coordinates;
  const to = np.properties.endCoords;
  const time = np.properties.time;
  const endTime = np.properties.endTime;

  const totalDistance = distance(from, to);
  const ratio = distanceToPoint / totalDistance;
  const totalDurationInSeconds = time - endTime;
  const duration = ratio * totalDurationInSeconds;

  // Determine if the nearest point is before or after the clicked point
  const nearestPointVector = [to[0] - from[0], to[1] - from[1]];
  const clickedPointVector = [coordinate[0] - from[0], coordinate[1] - from[1]];

  /* projectionOnSegment depicts if clicked point is projected onto the positive or negative side of the segment,
  which determines if we should move forward or backward in time from the start point. */
  const projectionOnSegment =
    nearestPointVector[0] * clickedPointVector[0] + nearestPointVector[1] * clickedPointVector[1];

  if (projectionOnSegment >= 0) {
    return time - duration;
  }

  return time + duration;
};

const resamplePath = (
  coordinates?: [number, number][],
  timing: number[] = [],
  segmentLengthInKm?: number
): any[] | undefined => {
  if (!coordinates || !segmentLengthInKm) {
    return undefined;
  }

  const line = lineString(coordinates);
  const totalLength = turfLength(line);

  const numPoints = Math.ceil(totalLength / segmentLengthInKm);
  const newPaths: Point[] = [];

  // Interpolate points along the line
  for (let i = 0; i <= numPoints; i++) {
    const distance = (i + 0.5) * segmentLengthInKm; // review
    if (distance <= totalLength) {
      const point = turfAlongWithTime(line, distance, timing, { units: "kilometers" });
      newPaths.push(point as any);
    }
  }

  return newPaths;
};

/**
 * Validates if a given string is a valid latitude and longitude pair.
 *
 * @param {string} latlng - The string in the format "latitude, longitude".
 * @returns {boolean} True if the string is a valid lat/lng pair, false otherwise.
 */
const isValidLatLng = (latLng: string) => {
  // Split the string by the comma
  const parts = latLng.split(",");

  // Check if we have exactly two parts
  if (parts.length !== 2) return false;

  // Parse each part into a number
  const lat = parseFloat(parts[0].trim());
  const lng = parseFloat(parts[1].trim());

  // Validate the numeric range
  if (isNaN(lat) || isNaN(lng)) return false;

  return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
};

export {
  getInterpolatedTimeFromLines,
  getTotalArea,
  diffAreaObject,
  combineFeaturesIntoMultiPolygon,
  filterAreasListByGroupId,
  filterAreasListByName,
  buildAreasUrl,
  getLayerColor,
  getLayerBorderColor,
  getFeaturesTotalArea,
  createPolygonWithMask,
  selfIntersectingFeatureCollection,
  getBBoxFromZoomAndCenter,
  getBBoxFromPoints,
  resamplePath,
  isValidLatLng,
};
