import { along, distance } from "@turf/turf";

const turfAlongWithTime = (line: any, dist: number, times: number[], options: any = {}) => {
  const point = along(line, dist, options);
  const coords = line.geometry.coordinates;
  let bearingCoordinates = undefined;

  // Calculate the cumulative distances along the line
  let cumulativeDistances = [0];
  for (let i = 1; i < coords.length; i++) {
    cumulativeDistances.push(cumulativeDistances[i - 1] + distance(coords[i - 1], coords[i], options));
  }

  let interpolatedTime;
  for (let i = 1; i < cumulativeDistances.length; i++) {
    if (dist <= cumulativeDistances[i]) {
      const startTime = times[i - 1];
      const endTime = times[i];
      const d1 = cumulativeDistances[i - 1];
      const d2 = cumulativeDistances[i];
      bearingCoordinates = coords[i];
      interpolatedTime = startTime + ((endTime - startTime) * (dist - d1)) / (d2 - d1);
      break;
    }
  }

  return {
    type: "Feature",
    geometry: point.geometry,
    properties: {
      time: interpolatedTime,
      bearingCoordinates,
    },
  };
};

export { turfAlongWithTime };
