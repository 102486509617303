// @ts-ignore
import ToColor from "@mapbox/to-color";
import colorString from "color-string";
import dayjs, { Dayjs } from "dayjs";
import { getDefaultStore } from "jotai";
import { isEmpty, isNumber } from "lodash";
import { BareFetcher } from "swr";

import { mapViewAtom } from "components/map/atoms/map";
import { dayTotalsUrlAtom } from "components/map/atoms/timeline/fetch-daytotals";

import {
  dayTotalsReportDayOfWeek,
  defaultTripColor,
  maxTimelineDays,
  showDailyTotalsInLiveView,
} from "components/map/config";
import { isUndefinedOrNull } from "components/map/utils";

import { generateRandomColor } from "utils/colors";
import { IHttpClientConfig } from "utils/http-client-utils";

const store = getDefaultStore();

const generateDates = (min?: Dayjs, max?: Dayjs) => {
  const dates = [];
  const lastDay = max?.subtract(1, "day");

  for (let date = min; date?.subtract(1, "day").isBefore(lastDay); date = date?.add(1, "day")) {
    dates.push(date?.startOf("day"));
  }

  dates.push(getTimelineEndDate(max));

  return dates;
};

const getExceedMAXTimelineDays = (range: [Dayjs, Dayjs] | null | undefined, maxDays = maxTimelineDays) => {
  const start = range?.[0];
  const end = range?.[1];

  if (!end && !start) {
    return false;
  }

  return (end?.diff(start, "day", true) || 0) > maxDays;
};

const getChunkedTimelineURL = (timelineURL: string, shouldFetch: boolean, startDate?: Dayjs, endDate?: Dayjs) => {
  if (!shouldFetch) {
    return null;
  }

  if (!startDate && !endDate) {
    return [timelineURL];
  }

  // set end date to start of next day if time is 23:59
  const timelineEndDate = getTimelineEndDate(endDate);

  const timeRange = endDate?.diff(startDate, "day");
  const start = startDate?.toISOString();

  if (isNumber(timeRange)) {
    if (timeRange < maxTimelineDays) {
      const end = dayjs(timelineEndDate).toISOString();

      const url = `${timelineURL}&start_time=${start}&end_time=${end}`;

      return [url];
    }

    const chunkedUrls: string[] = [];

    const generatedDates = generateDates(startDate, endDate);
    let startTime = startDate;

    generatedDates.forEach((date, index) => {
      const isMonday = date?.day() === dayTotalsReportDayOfWeek;
      const isLastEntry = index + 1 === generatedDates.length;
      let endTime = date;

      if (isMonday || isLastEntry) {
        chunkedUrls.push(`${timelineURL}&start_time=${startTime?.toISOString()}&end_time=${endTime?.toISOString()}`);
        startTime = endTime;
      }
    });

    return chunkedUrls;
  }

  return [];
};

const getTimelineEndDate = (dateTime?: Dayjs) => {
  if (!dateTime) {
    return dateTime;
  }

  const endOfDay = dateTime.endOf("day");
  const isEndOfDay = dateTime.isSame(endOfDay, "seconds");

  if (isEndOfDay) {
    return dateTime.add(1, "day").startOf("day");
  }

  return dateTime;
};

const fetchAllInParallel = (fetcher: BareFetcher<any>, config?: IHttpClientConfig) => (urls: string[]) => {
  return Promise.all(
    urls?.map((url) => {
      return fetcher(url, config);
    })
  );
};

const transformObjectToURLParams = (obj?: { [key: string]: boolean }) => {
  if (isUndefinedOrNull(obj)) {
    return undefined;
  }

  if (isEmpty(obj)) {
    return "";
  }

  return Object.keys(obj)
    .filter((key) => obj[key])
    .join(",");
};

const buildTimelineUrl = (
  timelineURL: URL,
  activities: string | undefined,
  events: string | undefined,
  totals: string | undefined,
  includeGeometry: boolean
) => {
  const removeTotals = !isEmpty(store.get(dayTotalsUrlAtom));
  const view = store.get(mapViewAtom);

  if (!isUndefinedOrNull(activities)) {
    timelineURL.searchParams.set("activities", activities || "");
  }
  if (!isUndefinedOrNull(events)) {
    timelineURL.searchParams.set("vehicle_events", events || "");
  }

  if (!isUndefinedOrNull(totals)) {
    if ((showDailyTotalsInLiveView && view === "live") || ["timeline", "playback"].includes(view as string)) {
      timelineURL.searchParams.set("totals", totals as string);
    }
  }

  if (removeTotals) {
    timelineURL.searchParams.set("totals", "");
  }

  timelineURL.searchParams.set("include_geometry", `${includeGeometry}`);
  timelineURL.searchParams.set("include_timing", `${includeGeometry}`);

  return timelineURL.href;
};

const getTripColor = (seed: string, enable: boolean, asHex?: boolean) => {
  if (!enable) {
    return asHex ? defaultTripColor.HEX : defaultTripColor.RGB;
  }

  const color = generateRandomColor(seed, "light");

  if (asHex) {
    return color;
  }
  const rgbColor = colorString.get.rgb(color || defaultTripColor.HEX);
  rgbColor?.pop();

  return rgbColor;
};

const hexToRgb = (hexColor: string) => {
  const rgbColor = colorString.get.rgb(hexColor);
  rgbColor?.pop();

  return rgbColor;
};

export {
  getChunkedTimelineURL,
  getExceedMAXTimelineDays,
  buildTimelineUrl,
  getTimelineEndDate,
  fetchAllInParallel,
  getTripColor,
  hexToRgb,
  transformObjectToURLParams,
};
