import { t } from "@lingui/macro";
import { message } from "antd";
import { atom, getDefaultStore } from "jotai";
import { atomEffect } from "jotai-effect";
import { isEmpty, last } from "lodash";

import { httpClientAtom } from "atoms/httpclient";
import { mapViewAtom } from "components/map/atoms/map";
import { scrollToTimelineEventIndexAtom, timelineExtentAtom } from "components/map/atoms/timeline";
import { dayTotalsStorageAtom } from "components/map/atoms/timeline/daytotals";
import { playbackStatusAtom } from "components/map/atoms/timeline/playback";
import { timelineURLBasedOnPlaybackDatesAtom, timelineURLsAtom } from "components/map/atoms/timeline/url";
import { fetchAllInParallel } from "components/map/atoms/timeline/utils";

import { httpStatusCodes } from "utils/status-codes-utils";

const timelineDataAtom = atom<{ [key: string]: any[] }>((get) => {
  const playbackTimelineData = get(playbackData_Atom);
  const timelineData = get(timelineData_Atom);

  const view = get(mapViewAtom);

  if (!isEmpty(playbackTimelineData) && view === "playback") {
    return playbackTimelineData;
  }

  return timelineData;
});
timelineDataAtom.debugLabel = "timelineData";

const timelineData_Atom = atom<{ [key: string]: any[] }>({});
timelineData_Atom.debugPrivate = true;

const timelineResponseErrorAtom = atom<{ [key: string]: string[] }>({});
timelineResponseErrorAtom.debugPrivate = true;

const playbackData_Atom = atom<{ [key: string]: any[] }>({});
playbackData_Atom.debugPrivate = true;

const timelineLoadingAtom = atom<boolean>(false);
timelineLoadingAtom.debugLabel = "timelineLoading";

const timelineResponseDateAtom = atom<string>();
timelineResponseDateAtom.debugPrivate = true;

// This fetches timeline data for a single day in multi days mode.
const fetchTimelineUsingPlaybackDateEffectAtom = atomEffect((get, set) => {
  const timelineURLs = JSON.parse(get(timelineURLBasedOnPlaybackDatesAtom));

  if (isEmpty(timelineURLs)) {
    return;
  }

  set(timelineLoadingAtom, true);

  const fetchPromises = Object.keys(timelineURLs).map(async (key) => {
    const urls = timelineURLs[key];

    return fetchAllInParallel(get(httpClientAtom).swrInfiniteFetcher)(urls as string[])
      .then((data) => {
        set(playbackData_Atom, (res) => ({ ...res, [key]: data }));
      })
      .catch((e) => {
        message.error(t`An error occurred fetching timeline data`);
        console.error(e);
      });
  });

  Promise.all(fetchPromises)
    .then(() => {
      set(timelineLoadingAtom, false);
    })
    .catch(() => {
      set(timelineLoadingAtom, false);
    });

  return () => {
    set(timelineExtentAtom, undefined);
    set(playbackData_Atom, {});
  };
});
fetchTimelineUsingPlaybackDateEffectAtom.debugPrivate = true;

const timelineEffectAtom = atomEffect((get, set) => {
  get(fetchTimelineUsingPlaybackDateEffectAtom);

  const timelineURLs = JSON.parse(get(timelineURLsAtom));
  const currentTimelineData = get.peek(timelineData_Atom); // Get current timeline data
  const excludedKeys: string[] = [];
  let results: any = {};

  // If there are no timeline URLs, return early
  if (isEmpty(timelineURLs)) {
    return;
  }

  const updatedTimelineData = Object.entries(currentTimelineData).reduce(
    (acc: { [key: string]: string[] }, [key, value]) => {
      if (timelineURLs[key]) {
        acc[key] = value;
      } else {
        excludedKeys.push(key);
      }
      return acc;
    },
    {}
  );

  // Step 2: Check if excludedKeys is not empty and timelineURLs has no new entries
  const existingKeys = Object.keys(currentTimelineData);
  const newKeys = Object.keys(timelineURLs).filter((key) => !existingKeys.includes(key));

  if (!isEmpty(excludedKeys) && isEmpty(newKeys)) {
    // No new entries in timelineURLs and excludedKeys is not empty, so prevent making requests
    set(timelineData_Atom, updatedTimelineData);
    return;
  }

  set(timelineLoadingAtom, true);
  set(timelineResponseDateAtom, undefined);

  // Step 3: Fetch data for the new URLs
  const fetchPromises = Object.keys(timelineURLs).map(async (key) => {
    const chunkedURLs = timelineURLs[key];

    return fetchAllInParallel(get(httpClientAtom).swrInfiniteFetcher, {
      errorHandler: (err) => {
        /* If error is related to totals, clear storage and reload browser */
        const hasTotalsError = err?.response?.data?.["totals"];
        const errorResponse = err?.response.data;

        if (errorResponse) {
          set(timelineResponseErrorAtom, (v) => ({ ...v, [key]: errorResponse }));
        }
        if (err?.response?.status === httpStatusCodes.BAD_REQUEST && hasTotalsError) {
          set(dayTotalsStorageAtom, {});
        }
      },
    })(chunkedURLs as string[])
      .then((data) => {
        results = { ...results, [key]: data };
        set(timelineResponseDateAtom, last(data)?.date);
      })
      .catch((e) => {
        message.error(t`An error occurred fetching timeline data.`);
        console.error(e);
      });
  });

  // Step 4: Handle loading state and any errors from the fetch process
  Promise.all(fetchPromises)
    .then(() => {
      set(timelineData_Atom, { ...updatedTimelineData, ...results });
      set(timelineLoadingAtom, false);
    })
    .catch(() => {
      set(timelineLoadingAtom, false);
    });

  // Cleanup function when the effect is disposed
  return () => {
    set(scrollToTimelineEventIndexAtom, undefined);
    set(timelineExtentAtom, undefined);
    set(playbackStatusAtom, "stop");
  };
});
timelineEffectAtom.debugPrivate = true;

const store = getDefaultStore();
const onRefreshTimelineData = () => {
  const timelineLoading = store.get(timelineLoadingAtom);
  const timelineURLs = JSON.parse(store.get(timelineURLsAtom));

  if (timelineLoading) {
    return;
  }

  Object.keys(timelineURLs).forEach((key: string) => {
    const chunkedUrls = timelineURLs?.[key];

    if (chunkedUrls) {
      store.set(timelineLoadingAtom, true);

      fetchAllInParallel(store.get(httpClientAtom).swrInfiniteFetcher)(chunkedUrls)
        .then((data) => {
          store.set(timelineData_Atom, () => ({ ...store.get(timelineData_Atom), [key]: data }));
          store.set(timelineLoadingAtom, false);
        })
        .catch((e) => {
          store.set(timelineLoadingAtom, false);
          message.error(t`An error occurred fetching timeline data`);
          console.error(e);
        });
    }
  });
};

export {
  timelineLoadingAtom,
  timelineEffectAtom,
  fetchTimelineUsingPlaybackDateEffectAtom,
  timelineDataAtom,
  timelineData_Atom,
  timelineResponseErrorAtom,
  timelineResponseDateAtom,
  onRefreshTimelineData,
  playbackData_Atom,
};
